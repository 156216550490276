import React, { FC } from 'react';
import { themes, ThemeType } from '@finbb/ui-components';

import styles from './ErrorMessage.module.css';

interface PropTypes {
  message: string;
}

const theme = themes.light as ThemeType;
const ErrorMessageStyles = {
  color: theme.values.colors.brand.HIGHLIGHT,
};

const ErrorMessage: FC<PropTypes> = ({ message }) => (
  <h1 className={styles.errorMessage} style={ErrorMessageStyles}>
    { message }
  </h1>
);

export default ErrorMessage;
