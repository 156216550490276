import React, { ReactElement, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { BiobankOfferWithCosts } from '../../common/types';
import PublishedBiobankOffer from '../../pages/PublishedBiobankOffer/PublishedBiobankOffer';
import UnpublishedBiobankOffer from '../../pages/UnpublishedBiobankOffer/UnpublishedBiobankOffer';
import { fetchBiobankOffer } from '../../services/api';
import LockedBiobankOffer from '../../pages/LockedBiobankOffer/LockedBiobankOffer';

function Offer(): ReactElement<Record<string, never>> | null {
  const { id }: { id?: string | undefined } = useParams();

  const [biobankOffer, setBiobankOffer] = useState<BiobankOfferWithCosts | null>(null);

  useEffect(() => {
    async function initBiobankOffer(): Promise<void> {
      if (id === undefined) {
        return setBiobankOffer(null);
      }
      const fetchedBiobankOffer = await fetchBiobankOffer(id);
      return setBiobankOffer(fetchedBiobankOffer);
    }
    initBiobankOffer();
  }, [id]);

  if (biobankOffer === null || id === undefined) {
    return null;
  }
  if (biobankOffer.publishedAt && biobankOffer.lockedAt === null) {
    return <PublishedBiobankOffer offerId={id} data={biobankOffer} />;
  }
  if ((biobankOffer.lockedAt !== null && biobankOffer.publishedAt !== null)
  || biobankOffer.archivedAt !== null) {
    return <LockedBiobankOffer />;
  }
  return (
    <UnpublishedBiobankOffer
      biobankOfferId={biobankOffer.id}
      initialWillNotParticipate={!!biobankOffer.willNotParticipate}
    />
  );
}

export default Offer;
