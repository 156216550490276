import React, { useContext, useState, useEffect } from 'react';
import { HeaderWithApplicationLinks as PlatformHeader, LinkButton } from '@finbb/ui-components';
import { AuthContext } from '../../context/authContext';

interface ApplicationLink {
  text: string;
  href: string;
}

const Header: React.FC = () => {
  const { authenticated, user, logOutUser } = useContext(AuthContext);
  const [applicationLinks, setApplicationLinks] = useState<ApplicationLink[]>([]);

  useEffect(
    () => {
      const links = JSON.parse(
        process.env.REACT_APP_APPLICATION_LINKS || '[]',
      ) as ApplicationLink[];

      if (authenticated) {
        setApplicationLinks(links);
      } else {
        setApplicationLinks((links.filter(
          (link) => link.text === process.env.REACT_APP_APPLICATION_NAME,
        )));
      }
    },
    [authenticated, setApplicationLinks],
  );

  const userMenu = authenticated
    ? {
      items: [
        {
          content: <LinkButton onClick={logOutUser} to="#" text="Logout" variant="UNOBTRUSIVE" />,
          id: 'logout',
        },
      ],
      text: user?.name || '',
    }
    : undefined;

  return (
    <PlatformHeader
      isAuthenticated={authenticated}
      applicationName="Offers"
      userMenu={userMenu}
      applicationLinks={applicationLinks}
    />
  );
};

export default Header;
