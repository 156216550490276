import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import StatusDate from './StatusDate';
import { BiobankOfferData } from '../../common/types';
import icon from '../../assets/imgs/icons8-file.svg';

import styles from './List.module.css';

interface PropTypes {
  offerList: Array<BiobankOfferData>;
}

const BiobankOfferList: FC<PropTypes> = ({ offerList }) => (
  <>
    {
      offerList.map(
        (biobankOffer: BiobankOfferData) => {
          const showAsPublished = !!(
            biobankOffer.publishedAt
            || biobankOffer.lockedAt
            || biobankOffer.archivedAt
          );
          const statusDate = showAsPublished ? <StatusDate offer={biobankOffer} /> : null;
          const className = showAsPublished ? styles.publishedListItem : styles.listItem;
          return (
            <li className={className} key={biobankOffer.id}>
              <Link to={`/offers/${biobankOffer.offerId}`}>
                <div className={styles.offer}>
                  <img className={styles.icon} src={icon} alt="icon" />
                  <div className={styles.titleContainer}>
                    <span>#</span>
                    <span className={styles.projectNum}>{biobankOffer.projectNum}</span>
                    <span>{biobankOffer.title}</span>
                  </div>
                  {statusDate}
                </div>
              </Link>
            </li>
          );
        },
      )
    }
  </>
);

export default BiobankOfferList;
