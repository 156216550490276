import React, { ReactElement, ReactNode } from 'react';
import { Accordion } from 'semantic-ui-react';

interface Props {
  children: ReactNode;
  active: boolean;
}

function AccordionContent(props: Props): ReactElement<Props> {
  const { children, active } = props;

  return (
    <Accordion.Content active={active}>
      <div>
        {children}
      </div>
    </Accordion.Content>
  );
}

export default AccordionContent;
