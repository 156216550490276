import React from 'react';
import {
  BrowserRouter, Navigate, Route, Routes,
} from 'react-router-dom';

import './App.css';
import { ThemeProvider } from 'styled-components';
import { themes, ThemeType } from '@finbb/ui-components';
import Header from './components/Header/Header';
import { AuthProvider } from './context/authContext';
import { ConfirmServiceProvider } from './context/confirmContext';
import AdminOffer from './routes/AdminOffer/AdminOffer';
import ProtectedRoute from './routes/AuthenticatedRoute/ProtectedRoute';
import EditOffer from './routes/EditOffer/EditOffer';
import Login from './routes/Login/Login';
import NewOffer from './routes/NewOffer/NewOffer';
import NotFound from './routes/NotFound/NotFound';
import Offer from './routes/Offer/Offer';
import OfferList from './routes/OfferList/OfferList';

const App: React.FC = () => {
  const theme = themes.light as ThemeType;
  return (
    <div className="App" data-testid="app-container">
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <ConfirmServiceProvider>
            <BrowserRouter>
              <Header />
              <main className="main-container">
                <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route element={<ProtectedRoute />}>
                    <Route path="/offers" element={<OfferList />} />
                    <Route path="/offers/:id" element={<Offer />} />
                    <Route path="/admin/offers/new" element={<NewOffer />} />
                    <Route path="/admin/offers/:id/" element={<AdminOffer />} />
                    <Route path="/admin/offers/:id/edit" element={<EditOffer />} />
                  </Route>
                  <Route path="/" element={<Navigate to="/offers" replace />} />
                  <Route path="/*" element={<NotFound />} />
                </Routes>
              </main>
            </BrowserRouter>
          </ConfirmServiceProvider>
        </ThemeProvider>
      </AuthProvider>
    </div>
  );
};
export default App;
