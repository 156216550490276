/* eslint-disable max-len */
import React, {
  ReactElement, ChangeEvent, useEffect, useState, FormEvent,
} from 'react';
import { useParams } from 'react-router-dom';
import { Header, TextAreaProps } from 'semantic-ui-react';

import NarrowContainer from '../../components/NarrowContainer/NarrowContainer';
import SharedCostForm from '../../components/SharedCostForm/SharedCostForm';
import BiobankOffer from '../../components/BiobankOffer/BiobankOffer';
import OfferTotal from '../../components/OfferTotal/OfferTotal';
import {
  fetchCosts, fetchSharedCosts, fetchBiobankOffers, fetchProject,
} from '../../services/api';
import {
  CategoryWithSections, BiobankOfferWithCosts, MainCostData, SubCostData, OfferData,
} from '../../common/types';

function marginAsPercentage(margin: string): number {
  const float = parseFloat(margin);

  return (float || 0) / 100;
}

function serviceFeeToEur(serviceFee: string): number {
  const float = parseFloat(serviceFee);

  return float || 0;
}

const AdminOffer = (): ReactElement<Record<string, never>> => {
  const params: { id?: string | undefined } = useParams();
  const { id } = params;

  const [offer, setOffer] = useState<OfferData | null>(null);
  const [offerDescription, setOfferDescription] = useState('');
  const [serviceFee, setServiceFee] = useState('');
  const [serviceFeeComment, setServiceFeeComment] = useState('');
  const [margin, setMargin] = useState('');
  const [primaryComment, setPrimaryComment] = useState('');
  const [secondaryComment, setSecondaryComment] = useState('');
  const [signingFee, setSigningFee] = useState('');
  const [signingFeePaymentTerms, setSigningFeePaymentTerms] = useState('');
  const [remainingFeePaymentTerms, setRemainingFeePaymentTerms] = useState('');
  const [categories, setCategories] = useState<Array<CategoryWithSections>>([]);
  const [biobankOffers, setBiobankOffers] = useState<Array<BiobankOfferWithCosts>>([]);

  useEffect(() => {
    async function initOffer(): Promise<void> {
      if (id === undefined) {
        return;
      }

      const fetchedProject = await fetchProject(id);

      setOffer(fetchedProject);
    }

    async function initCosts(): Promise<void> {
      const fetchedCosts = await fetchCosts();

      setCategories(fetchedCosts);
    }

    async function initSharedCosts(): Promise<void> {
      if (id === undefined) {
        return;
      }

      const fetchedSharedCosts = await fetchSharedCosts(id);

      setOfferDescription(fetchedSharedCosts.offerDescription || '');
      setServiceFee(fetchedSharedCosts.serviceFeeInEur?.toString() || '');
      setServiceFeeComment(fetchedSharedCosts.serviceFeeComment || '');
      setMargin(fetchedSharedCosts.margin?.toString() || '');
      setPrimaryComment(fetchedSharedCosts.primaryComment || '');
      setSecondaryComment(fetchedSharedCosts.secondaryComment || '');
      setSigningFee(fetchedSharedCosts.signingFee || '');
      setSigningFeePaymentTerms(fetchedSharedCosts.signingFeePaymentTerms ? fetchedSharedCosts.signingFeePaymentTerms.toString() : '');
      setRemainingFeePaymentTerms(fetchedSharedCosts.remainingFeePaymentTerms ? fetchedSharedCosts.remainingFeePaymentTerms.toString() : '');
    }

    async function initBiobankOffers(): Promise<void> {
      if (id === undefined) {
        return setBiobankOffers([]);
      }

      const fetchedBiobankOffers = await fetchBiobankOffers(id);

      return setBiobankOffers(fetchedBiobankOffers);
    }

    initOffer();
    initCosts();
    initSharedCosts();
    initBiobankOffers();
  }, [id]);

  function handleOfferDescriptionChange(event: FormEvent<HTMLTextAreaElement>, data: TextAreaProps): void {
    if (typeof data.value !== 'string') {
      return;
    }

    setOfferDescription(data.value);
  }

  function handleServiceFeeChange(
    event: ChangeEvent<HTMLInputElement>,
  ): void {
    setServiceFee(event.target.value);
  }

  function handleServiceFeeCommentChange(event: FormEvent<HTMLTextAreaElement>, data: TextAreaProps): void {
    if (typeof data.value !== 'string') {
      return;
    }

    setServiceFeeComment(data.value);
  }

  function handleMarginChange(event: ChangeEvent<HTMLInputElement>): void {
    setMargin(event.target.value);
  }

  function handlePrimaryCommentChange(event: FormEvent<HTMLTextAreaElement>, data: TextAreaProps): void {
    if (typeof data.value !== 'string') {
      return;
    }

    setPrimaryComment(data.value);
  }

  function handleSecondaryCommentChange(event: FormEvent<HTMLTextAreaElement>, data: TextAreaProps): void {
    if (typeof data.value !== 'string') {
      return;
    }

    setSecondaryComment(data.value);
  }

  function handleSigningFeeChange(
    event: ChangeEvent<HTMLInputElement>,
  ): void {
    setSigningFee(event.target.value);
  }

  function handleSigningFeePaymentTermsChange(
    event: ChangeEvent<HTMLInputElement>,
  ): void {
    setSigningFeePaymentTerms(event.target.value);
  }

  function handleRemainingFeePaymentTermsChange(
    event: ChangeEvent<HTMLInputElement>,
  ): void {
    setRemainingFeePaymentTerms(event.target.value);
  }

  function allBiobankOffersPublished(): boolean {
    const unpublishedBiobankOffers = biobankOffers.filter(
      (biobankOffer) => biobankOffer.publishedAt === null,
    );

    return unpublishedBiobankOffers.length === 0;
  }

  const marginPercentage: number = marginAsPercentage(margin);
  const serviceFeeInEur: number = serviceFeeToEur(serviceFee);
  const costData: Array<MainCostData | SubCostData> = biobankOffers.reduce((result, biobankOffer) => {
    if (biobankOffer.willNotParticipate) {
      return result;
    }
    return [...result, ...biobankOffer.costs];
  }, [] as Array<MainCostData | SubCostData>);

  if (id === undefined) {
    return <div>404</div>;
  }

  return (
    <NarrowContainer>
      <SharedCostForm
        offer={offer}
        ready={allBiobankOffersPublished()}
        uneditable={offer?.archivedAt !== null}
        offerDescription={offerDescription}
        onOfferDescriptionChange={handleOfferDescriptionChange}
        serviceFee={serviceFee}
        onServiceFeeChange={handleServiceFeeChange}
        serviceFeeComment={serviceFeeComment}
        onServiceFeeCommentChange={handleServiceFeeCommentChange}
        margin={margin}
        onMarginChange={handleMarginChange}
        primaryComment={primaryComment}
        onPrimaryCommentChange={handlePrimaryCommentChange}
        secondaryComment={secondaryComment}
        onSecondaryCommentChange={handleSecondaryCommentChange}
        signingFee={signingFee}
        onSigningFeeChange={handleSigningFeeChange}
        signingFeePaymentTerms={signingFeePaymentTerms}
        onSigningFeePaymentTermsChange={handleSigningFeePaymentTermsChange}
        remainingFeePaymentTerms={remainingFeePaymentTerms}
        onRemainingFeePaymentTermsChange={handleRemainingFeePaymentTermsChange}
      />
      <Header
        as="h2"
        content="Total"
        dividing
      />

      <OfferTotal margin={marginPercentage} serviceFee={serviceFeeInEur} data={costData} />
      {biobankOffers.map((biobankOffer) => (
        <BiobankOffer
          key={biobankOffer.id}
          categories={categories}
          data={biobankOffer}
        />
      ))}
    </NarrowContainer>
  );
};

export default AdminOffer;
