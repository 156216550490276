import React, { ReactElement } from 'react';

import styles from './SectionHeading.module.css';

interface Props {
  title: string;
}

function SectionHeading(props: Props): ReactElement<Props> {
  const { title } = props;

  return (
    <h4 className={styles.wrapper}>{title}</h4>
  );
}

export default SectionHeading;
