// React Libraries
import React from 'react';

// Types/Data Models

// API Services
import { useAuth0 } from '@auth0/auth0-react';

// Assets
import { useNavigate } from 'react-router-dom';
import { ButtonType } from '@finbb/ui-components';
import styles from './Login.module.css';

import ActionableMessage from '../../components/ActionableMessage/ActionableMessage';
import CenteredParagraph from '../../components/CenteredParagraph/CenteredParagraph';

const Login = (): JSX.Element => {
  const navigate = useNavigate();
  const { loginWithPopup } = useAuth0();

  const platformSignUp = process.env.REACT_APP_REGISTRATION_URL || '';
  const platformPrivacyPolicy = `${process.env.REACT_APP_PLATFORM_URL}/privacy-policy`;

  const login = async () => {
    await loginWithPopup();
    navigate('/offers');
  };

  const signup = () => {
    window.location.href = platformSignUp;
  };

  const buttons: ButtonType[] = [
    {
      onClick: login,
      text: 'Log in',
      variant: 'CALL_TO_ACTION',
    },
    {
      onClick: signup,
      text: 'Register',
    },
  ];

  return (
    <div className={styles.background}>
      <div className={styles.container}>
        <ActionableMessage
          buttons={buttons}
          heading="Welcome!"
          message="Welcome to the Fingenious pricing tool."
        />
      </div>
      <CenteredParagraph
        text="Privacy policy"
        link={platformPrivacyPolicy}
        openInNewTab
      />
    </div>
  );
};

export default Login;
