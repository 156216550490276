import React, {
  useState, FormEvent, useEffect, useReducer,
} from 'react';
import { Form } from 'semantic-ui-react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './NewOffer.module.css';
import { saveNewOffer, fetchBiobanks } from '../../services/api';
import { Biobank, NewOfferData } from '../../common/types';

const NewOffer: React.FC<any> = () => {
  const history = useNavigate();
  const [isFormValid, setIsFormValid] = useState(false);
  const [biobankOptions, setBiobankOptions] = useState<Array<Biobank> | null>(null);

  const initialState = {
    title: '',
    projectNum: '',
    researcher: '',
    researcherEmail: '',
    companyName: '',
    companyAddress1: '',
    companyAddress2: '',
    finbbContact: '',
    finbbContactEmail: '',
    type: 'Estimate',
    biobanks: [],
  };

  const reducer = (state: NewOfferData, action: any): NewOfferData => {
    switch (action.type) {
      case 'setState': return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
      default: throw new Error('Unexpected action');
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    async function loadBiobanks(): Promise<void> {
      const loadedBiobanks = await fetchBiobanks().catch((err) => console.error(err.response));
      if (loadedBiobanks) {
        setBiobankOptions(loadedBiobanks);
      }
    }
    loadBiobanks();
  }, []);

  useEffect(() => {
    if (state.title.length > 0
        && state.projectNum.length > 0
        && state.researcher.length > 0
        && state.researcherEmail.length > 0
        && state.finbbContact.length > 0
        && state.finbbContactEmail.length > 0
        && state.biobanks.length > 0) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [state]);

  const onFormSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault();
    const newOffer = { ...state };
    await saveNewOffer(newOffer).catch((err) => console.error(err));
    history('/offers');
  };

  const toggleBiobankSelected = (toggledBiobank: Biobank): void => {
    if (state.biobanks.find((biobank) => biobank.id === toggledBiobank.id)) {
      const filtered = state.biobanks.filter((biobank) => biobank.id !== toggledBiobank.id);
      dispatch({ type: 'setState', payload: { name: 'biobanks', value: [...filtered] } });
    } else {
      const selected = state.biobanks.concat({ ...toggledBiobank });
      dispatch({ type: 'setState', payload: { name: 'biobanks', value: [...selected] } });
    }
  };
  const handleChange = (name: string, value: string): void => {
    dispatch({ type: 'setState', payload: { name, value } });
  };
  const biobankCheckBoxes = biobankOptions?.map((biobank: Biobank) => (
    <Form.Checkbox
      key={biobank.id}
      label={biobank.name}
      onChange={(): void => toggleBiobankSelected(biobank)}
    />
  ));
  return (
    <div className={styles.container}>
      <header><h1>Create new offer</h1></header>
      <Form className={styles.form} size="small" onSubmit={onFormSubmit} error>
        <div className={styles.radioGroupContainer}>
          <Form.Radio
            label="Estimate"
            name="offerTypeGroup"
            value="Estimate"
            required
            onChange={(e, data) => handleChange('type', data.value as string)}
            checked={state.type === 'Estimate'}
            className={styles.radioGroupItem}
          />
          <Form.Radio
            label="Offer"
            name="offerTypeGroup"
            value="Offer"
            required
            onChange={(e, data) => handleChange('type', data.value as string)}
            checked={state.type === 'Offer'}
            className={styles.radioGroupItem}
          />
        </div>
        <Form.Input
          value={state.title}
          required
          label="Project name"
          onChange={(e) => handleChange('title', e.target.value)}
        />
        <Form.Input
          value={state.projectNum}
          required
          type="text"
          label="Project number"
          onChange={(e) => handleChange('projectNum', e.target.value)}
        />
        <Form.Input
          value={state.researcher}
          required
          type="text"
          label="Researcher"
          onChange={(e) => handleChange('researcher', e.target.value)}
        />
        <Form.Input
          value={state.researcherEmail}
          required
          type="email"
          label="Researcher email"
          onChange={(e) => handleChange('researcherEmail', e.target.value)}
        />
        <Form.Input
          value={state.companyName}
          type="text"
          label="Company name"
          onChange={(e) => handleChange('companyName', e.target.value)}
        />
        <Form.Input
          value={state.companyAddress1}
          type="text"
          label="Company address (line 1)"
          onChange={(e) => handleChange('companyAddress1', e.target.value)}
        />
        <Form.Input
          value={state.companyAddress2}
          type="text"
          label="Company address (line 2)"
          onChange={(e) => handleChange('companyAddress2', e.target.value)}
        />
        <h2 className={styles.checkboxHeader}>FINBB contact person</h2>
        <Form.Input
          value={state.finbbContact}
          required
          type="text"
          label="Contact name"
          onChange={(e) => handleChange('finbbContact', e.target.value)}
        />
        <Form.Input
          value={state.finbbContactEmail}
          required
          type="email"
          label="Contact email"
          onChange={(e) => handleChange('finbbContactEmail', e.target.value)}
        />
        <h2 className={styles.checkboxHeader}>Select biobanks</h2>
        {biobankCheckBoxes}
        <div className={styles.submitButton}>
          <div className="mockButton grey">
            <Link className={styles.cancel} to="/offers">Cancel</Link>
          </div>
          <Form.Button
            circular
            onClick={onFormSubmit}
            color="blue"
            disabled={!isFormValid}
          >
            Save
          </Form.Button>
        </div>

      </Form>
    </div>
  );
};

export default NewOffer;
