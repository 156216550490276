import React, { ReactElement } from 'react';
import { Table } from 'semantic-ui-react';

import { Section as SectionData, SubCostData } from '../../common/types';
import TableHeader from './TableHeader';
import Subcategory from './Subcategory';

interface Props {
  section: SectionData;
  data: Array<SubCostData>;
}

function Section(props: Props): ReactElement<Props> | null {
  const { section, data } = props;

  if (section.costs.length === 0) {
    return null;
  }

  return (
    <Table singleline="true">
      {section.header.title.length > 0 && <TableHeader title={section.header.title} />}
      <Table.Body>
        {section.costs.map((subcategory) => {
          const subcategoryData = data.find((item) => item.id === subcategory.id);

          return (
            <Subcategory
              key={subcategory.id}
              subcategory={subcategory}
              data={subcategoryData}
            />
          );
        })}
      </Table.Body>
    </Table>
  );
}

export default Section;
