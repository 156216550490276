import React from 'react';
import ReactDOM from 'react-dom';
import ConfirmDialog from './ConfirmDialog';

const getUserConfirmation = (
  message: string,
  callback: (answer: boolean) => any,
  confirmText?: string,
): any => {
  const modal = document.createElement('div');
  document.body.appendChild(modal);

  const withCleanup = (answer: boolean) => {
    ReactDOM.unmountComponentAtNode(modal);
    document.body.removeChild(modal);
    callback(answer);
  };

  ReactDOM.render(
    <ConfirmDialog
      open
      onClose={() => withCleanup(false)}
      onConfirm={() => withCleanup(true)}
      title="Are you sure you want to leave the page?"
      description={message}
      confirmText={confirmText}
    />,
    modal,
  );
};

export default getUserConfirmation;
