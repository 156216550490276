/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { ReactElement } from 'react';
import { Accordion, Icon } from 'semantic-ui-react';

import styles from './Title.module.css';

interface Props {
  title: string;
  active: boolean;
  onToggle: () => void;
  right?: ReactElement;
}

function Title(props: Props): ReactElement<Props> {
  const {
    title, active, onToggle, right,
  } = props;

  return (
    <Accordion.Title className={styles.outerWrapper}>
      <div className={styles.innerWrapper}>
        <div
          className={styles.left}
          onClick={onToggle}
          onKeyPress={onToggle}
        >
          <div>{title}</div>
          <div>
            { active
              ? <Icon className={styles.accordionIcon} name="angle down" />
              : <Icon className={styles.accordionIcon} name="angle right" />}
          </div>
        </div>
        {right && (
        <div className={styles.rightOuter}>
          <div className={styles.rightInner}>
            {right}
          </div>
        </div>
        )}
      </div>
    </Accordion.Title>
  );
}

Title.defaultProps = {
  right: undefined,
};

export default Title;
