import React, { FC } from 'react';

import styles from './List.module.css';
import { formatTimestamp, getTextForDate } from '../../services/utils';
import { BiobankOfferData, OfferData } from '../../common/types';

interface PropTypes {
  offer: OfferData | BiobankOfferData;
}

const StatusDate: FC<PropTypes> = ({ offer }) => {
  const date = 'publishedAt' in offer
    ? (offer.archivedAt || offer.lockedAt || offer.publishedAt) as string
    : (offer.archivedAt || offer.lockedAt) as string;
  const text = getTextForDate(offer);

  return (
    <div className={styles.statusDate}>
      <span className={styles.status}>{text}</span>
      {formatTimestamp(date)}
    </div>
  );
};

export default StatusDate;
