import React, {
  ReactElement, useEffect, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { BiobankOfferWithCosts, CategoryWithSections, OfferData } from '../../common/types';
import { fetchCosts, fetchProject, cancelPublish } from '../../services/api';
import styled from './PublishedBiobankOffer.module.css';
import NarrowContainer from '../../components/NarrowContainer/NarrowContainer';
import BiobankOffer from '../../components/BiobankOffer/BiobankOffer';
import OfferHeader from '../../components/OfferHeaderAlt/OfferHeaderAlt';
import HeaderButton from '../../components/HeaderButton/HeaderButton';

interface Props {
  offerId: string;
  data: BiobankOfferWithCosts;
}

function PublishedBiobankOffer(props: Props): ReactElement<Props> | null {
  const { offerId, data } = props;

  const history = useNavigate();

  const [offer, setOffer] = useState<OfferData | null>(null);
  const [categories, setCategories] = useState<Array<CategoryWithSections>>([]);
  const [unpublishButtonLoading, setUnpublishButtonLoading] = useState(false);

  useEffect(() => {
    async function initOffer(): Promise<void> {
      const fetchedProject = await fetchProject(offerId);

      setOffer(fetchedProject);
    }

    async function initCosts(): Promise<void> {
      const fetchedCosts = await fetchCosts();

      setCategories(fetchedCosts);
    }

    initOffer();
    initCosts();
  }, [offerId]);

  async function handleUnpublishButtonClick(): Promise<void> {
    setUnpublishButtonLoading(true);
    await cancelPublish(data.id).catch((err) => console.error(err.response));
    history('/offers');
  }

  if (offer === null) {
    return null;
  }

  return (
    <NarrowContainer>
      <div className={styled.wrapper}>
        <OfferHeader title={offer.title} type={offer.type} project={offer.projectNum.toString()} version={offer.version || 'n/a'} />
        <HeaderButton type="button" primary loading={unpublishButtonLoading} onClick={handleUnpublishButtonClick}>
          Unpublish
        </HeaderButton>
        <BiobankOffer data={data} categories={categories} />
      </div>
    </NarrowContainer>
  );
}

export default PublishedBiobankOffer;
