import { MainCostData, SubCostData } from '../common/types';

interface DefaultCost {
  amountInEur: number;
}

interface HourlyCost extends DefaultCost {
  hours: number;
}

interface UnitCost extends DefaultCost {
  units: number;
}

export function hourlyCostInEur(data: HourlyCost): number {
  return data.amountInEur * data.hours;
}

export function unitCostInEur(data: UnitCost): number {
  return data.amountInEur * data.units;
}

export function totalInEur(data: Array<MainCostData | SubCostData>): number {
  const total = data.reduce((sum, cost) => {
    if (cost.type === 'main') {
      return sum + cost.amountInEur;
    }

    let result = sum;

    if (cost.hourly.amountInEur !== null && cost.hourly.hours !== null) {
      const hourlyData = { amountInEur: cost.hourly.amountInEur, hours: cost.hourly.hours };
      result += hourlyCostInEur(hourlyData);
    }

    if (cost.unit.amountInEur !== null && cost.unit.units !== null) {
      const unitData = { amountInEur: cost.unit.amountInEur, units: cost.unit.units };
      result += unitCostInEur(unitData);
    }

    if (cost.main.amountInEur !== null) {
      result += cost.main.amountInEur;
    }

    return result;
  }, 0);

  return total;
}
