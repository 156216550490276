import axios from 'axios';

const base = process.env.REACT_APP_OFFERTOOL_API_URL;

axios.defaults.withCredentials = true;

const axiosInstance = axios.create({
  baseURL: base,
  withCredentials: true,
});

export default axiosInstance;
