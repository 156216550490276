/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
  useState, useEffect, ReactElement,
} from 'react';
import {
  Icon, Input, Label,
} from 'semantic-ui-react';

import {
  CostProps, SubCost, SubCostPrice,
} from '../../common/types';
import styles from './Cost.module.css';
import UiType1 from '../SubCosts/UiType1/UiType1';
import UiType2 from '../SubCosts/UiType2/UiType2';
import UiType3 from '../SubCosts/UiType3/UiType3';
import UiType4 from '../SubCosts/UiType4/UiType4';
import Accordion from '../Accordion/Accordion';
import SectionHeading from '../SectionHeading/SectionHeading';
import { mapUiTypeToInitialState } from '../../services/utils';
import OtherCosts from '../SubCosts/OtherCosts/OtherCosts';
import { useConfirm } from '../../context/confirmContext';

const Cost: React.FC<CostProps> = React.memo((props: CostProps) => {
  const {
    cost, onPriceChange, costPriceData,
  } = props;
  const confirm = useConfirm();
  const [priceInEur, setPriceInEur] = useState(0);
  const [subCostPrices, setSubCostPrices] = useState<Array<SubCostPrice> | null>(null);

  useEffect(() => {
    if (costPriceData.priceInEur) {
      setPriceInEur(costPriceData.priceInEur);
    }
    if (costPriceData.subCostPrices) {
      setSubCostPrices([...costPriceData.subCostPrices]);
    }
  }, [costPriceData]);

  const handleSubCostPriceChange = (priceData: SubCostPrice): void => {
    let updatedPrices;
    if (subCostPrices && subCostPrices.length > 0) {
      const previousValue = subCostPrices.find((price: any) => (
        price.subCostId === priceData.subCostId));
      if (previousValue) {
        updatedPrices = subCostPrices.map((price: any) => (
          price.subCostId === priceData.subCostId
            ? { ...priceData }
            : price
        ));
      } else {
        updatedPrices = subCostPrices?.concat({ ...priceData });
      }
      setSubCostPrices(updatedPrices);
      onPriceChange(
        {
          mainCostId: cost.mainCostId,
          priceInEur: 0,
          subCostPrices: updatedPrices,
        },
      );
    } else {
      setSubCostPrices([{ ...priceData }]);
      onPriceChange(
        {
          mainCostId: cost.mainCostId,
          priceInEur: 0,
          subCostPrices: [{ ...priceData }],
        },
      );
    }
  };

  const resetMainCostPriceInEur = (): void => {
    setPriceInEur(0);
  };

  const getSubCostPriceData = (id: string, uiType: number): any => {
    if (subCostPrices && subCostPrices.length > 0) {
      const subCostPrice = subCostPrices.find(
        (sub: any) => (sub.subCostId === id),
      );
      if (!subCostPrice) {
        const initialPriceState = mapUiTypeToInitialState(id, uiType);
        return initialPriceState;
      }
      return subCostPrice;
    }
    const initialPriceState = mapUiTypeToInitialState(id, uiType);
    return initialPriceState;
  };

  const subCostAccordingToUiType = (subCost: SubCost): ReactElement => {
    const subCostPrice = getSubCostPriceData(subCost.id, subCost.uiType);
    switch (subCost.uiType) {
      case 1:
        return (
          <UiType1
            onPriceChange={handleSubCostPriceChange}
            key={subCost.id}
            subCost={subCost}
            resetMainPrice={resetMainCostPriceInEur}
            mainPrice={priceInEur}
            priceData={subCostPrice}
          />
        );
      case 2:
        return (
          <UiType2
            onPriceChange={handleSubCostPriceChange}
            key={subCost.id}
            subCost={subCost}
            resetMainPrice={resetMainCostPriceInEur}
            mainPrice={priceInEur}
            priceData={subCostPrice}
          />
        );
      case 3:
        return (
          <UiType3
            onPriceChange={handleSubCostPriceChange}
            key={subCost.id}
            subCost={subCost}
            resetMainPrice={resetMainCostPriceInEur}
            mainPrice={priceInEur}
            priceData={subCostPrice}
          />
        );
      case 4:
        return (
          <UiType4
            onPriceChange={handleSubCostPriceChange}
            key={subCost.id}
            subCost={subCost}
            resetMainPrice={resetMainCostPriceInEur}
            mainPrice={priceInEur}
            priceData={subCostPrice}
          />
        );
      default:
        return <div>Invalid data</div>;
    }
  };
  let otherCostsElement;
  const subCostElements = cost.subCostData[0].subheaderId !== null
    ? cost.subCostData.map(
      (sub: any) => {
        const subCostsUnderHeader = sub.subCosts.map(
          (subCost: SubCost) => subCostAccordingToUiType(subCost),
        );
        if (sub.subCosts[0].title === 'Muut kustannukset') {
          const subCostPrice = subCostPrices?.find((price) => price.subCostId === sub.subCosts[0].id);
          const priceData = subCostPrice || { priceInEur: 0, comment: '', subCostId: sub.subCosts[0].id };
          otherCostsElement = (
            <div className={styles.container}>
              <SectionHeading title="Muut kustannukset" />
              <OtherCosts
                subCost={sub.subCosts[0]}
                priceData={priceData}
                onPriceChange={handleSubCostPriceChange}
                resetMainPrice={resetMainCostPriceInEur}
                mainPrice={priceInEur}
              />
            </div>
          );
          return null;
        }
        return (
          <div className={styles.container} key={sub.subheaderId}>
            <SectionHeading title={sub.subHeader} />

            <div>{subCostsUnderHeader}</div>
          </div>
        );
      },
    )
    : cost.subCostData.map((subCost: SubCost) => {
      if (subCost.title === 'Muut kustannukset') {
        const subCostPrice = subCostPrices?.find((price) => price.subCostId === subCost.id);
        const priceData = subCostPrice || { priceInEur: 0, comment: '', subCostId: subCost.id };
        otherCostsElement = (
          <div>
            <div className={styles.otherCostTitle}>Muut kustannukset</div>
            <OtherCosts
              subCost={subCost}
              priceData={priceData}
              onPriceChange={handleSubCostPriceChange}
              resetMainPrice={resetMainCostPriceInEur}
              mainPrice={priceInEur}
            />
          </div>
        );
        return null;
      }
      return subCostAccordingToUiType(subCost);
    });

  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.value) {
      const value = parseFloat(e.target.value);
      if (subCostPrices && subCostPrices?.length > 0) {
        confirm({
          title: 'This will overwrite sub costs',
          catchOnCancel: true,
          description: 'Are you sure you want to overwrite previously input data?',
        })
          .then(() => {
            setPriceInEur(value);
            onPriceChange(
              {
                mainCostId: cost.mainCostId,
                priceInEur: value,
                subCostPrices: [],
              },
            );
          })
          .catch(() => {});
      } else {
        setPriceInEur(value);
        onPriceChange(
          {
            mainCostId: cost.mainCostId,
            priceInEur: value,
            subCostPrices: [],
          },
        );
      }
    }
  };
  const handleFocus = (event: React.FocusEvent<HTMLInputElement>): void => event.target.select();

  return (
    <Accordion
      title={cost.mainCostTitle}
      titleRight={(
        <Input
          size="mini"
          iconPosition="left"
          labelPosition="right"
          type="number"
          min="0"
          onChange={handlePriceChange}
          value={priceInEur}
        >
          <Icon name="euro" />
          <input onFocus={handleFocus} />
          <Label horizontal content="total" />
        </Input>
)}
    >
      {subCostElements}
      {otherCostsElement}
    </Accordion>
  );
});

export default Cost;
