import React from 'react';

import CostListContainer from '../CostListContainer/CostListContainer';
import Cost from '../Cost/Cost';
import {
  CostListProps, MainCostPrice, ICost,
} from '../../common/types';
import { createCostPrice } from '../../services/utils';

const CostList: React.FC<CostListProps> = (props: CostListProps) => {
  const {
    costs, biobankOfferId, onPriceDataChange, priceData,
  } = props;

  const handlePriceChange = (data: MainCostPrice): void => {
    let updatedMainCostPrices: Array<MainCostPrice>;
    if (priceData.mainCostPrices.length > 0) {
      const prevData = priceData.mainCostPrices.find((
        main: MainCostPrice,
      ) => main.mainCostId === data.mainCostId);
      if (prevData) {
        updatedMainCostPrices = priceData.mainCostPrices.map(
          (main: MainCostPrice) => (
            main.mainCostId === data.mainCostId
              ? { ...data }
              : main
          ),
        );
      } else {
        updatedMainCostPrices = priceData.mainCostPrices.concat({ ...data });
      }
      onPriceDataChange({ ...priceData, mainCostPrices: [...updatedMainCostPrices] });
    } else {
      onPriceDataChange({ ...priceData, mainCostPrices: [{ ...data }] });
    }
  };

  const getCostPriceData = (mainId: string): MainCostPrice => {
    if (priceData?.mainCostPrices.length > 0) {
      const costPrice = priceData.mainCostPrices.find(
        (main: MainCostPrice) => (main.mainCostId === mainId),
      );
      return costPrice || createCostPrice(mainId);
    }
    return createCostPrice(mainId);
  };

  const elements = costs.map((cost: ICost) => (
    <Cost
      key={cost.mainCostId}
      cost={cost}
      biobankOfferId={biobankOfferId}
      onPriceChange={handlePriceChange}
      costPriceData={getCostPriceData(cost.mainCostId)}
    />
  ));
  return (
    <CostListContainer>
      {elements}
    </CostListContainer>
  );
};
export default CostList;
