/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import styles from './CostBreakdown.module.css';
import { calculateCostSums } from '../../services/utils';
import { CostBreakDownData, MainCost, CostBreakdownProps } from '../../common/types';
import { fetchMainCosts } from '../../services/api';

const CostBreakdown: React.FC<CostBreakdownProps> = React.memo((props: CostBreakdownProps) => {
  const { costPrices } = props;
  const [costData, setCostData] = useState<Array<CostBreakDownData> | null>(null);
  const [mainCosts, setMainCosts] = useState<Array<MainCost> | null>(null);

  useEffect(() => {
    if (costPrices) {
      const data = calculateCostSums(costPrices);
      setCostData(data);
    }
  }, [costPrices]);

  useEffect(() => {
    async function loadMainCosts(): Promise<void> {
      const loadedMainCosts = await fetchMainCosts();
      setMainCosts(loadedMainCosts);
    }
    loadMainCosts();
  }, []);

  if (costData) {
    const titlesAppendedAndSorted = costData.map((cost: CostBreakDownData) => {
      const mainCost = mainCosts?.find((main) => main.id === cost.mainCostId);
      return { ...cost, title: mainCost ? mainCost.title : '' };
    }).sort((a, b) => (a.title > b.title ? 1 : b.title > a.title ? -1 : 0));

    const breakdown = titlesAppendedAndSorted?.map((cost: CostBreakDownData) => (
      <div key={cost.mainCostId} className={styles.costBreakdownItem}>
        <div>{cost.title}</div>
        <div>
          {cost.sum.toFixed(2)}
          <span className={styles.currencyIcon}>€</span>
        </div>
      </div>
    ));

    return (
      <div className={styles.costBreakdown}>
        <div className={styles.costBreakdownHeader}>Total cost breakdown</div>
        <div className={styles.costBreakdownRow}>{breakdown}</div>
      </div>
    );
  }
  return null;
});

export default CostBreakdown;
