import React, { ReactElement, ReactNode, useState } from 'react';

import AccordionTitle from './AccordionTitle';
import AccordionContent from './AccordionContent';

interface Props {
  children: ReactNode;
  title: string;
  titleRight?: ReactElement;
}

function Accordion(props: Props): ReactElement<Props> {
  const { children, title, titleRight } = props;

  const [active, setActive] = useState(false);

  function handleAccordionToggle(): void {
    setActive(!active);
  }

  return (
    <>
      <AccordionTitle
        title={title}
        active={active}
        onToggle={handleAccordionToggle}
        right={titleRight}
      />
      <AccordionContent active={active}>
        {children}
      </AccordionContent>
    </>
  );
}

Accordion.defaultProps = {
  titleRight: undefined,
};

export default Accordion;
