/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  ReactElement, ChangeEvent, FormEvent, useState, useEffect,
} from 'react';
import {
  Form, Input, Icon, Button, TextAreaProps, Dropdown,
} from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import { useConfirm } from '../../context/confirmContext';
import styles from './SharedCostForm.module.css';
import {
  updateSharedCost, createPdf, duplicateProject, updateOffer, deleteOffer, createDocx,
} from '../../services/api';
import { SharedCost, OfferData } from '../../common/types';
import Message from '../Message/Message';
import OfferHeader from '../OfferHeaderAlt/OfferHeaderAlt';
import HeaderButtons from '../HeaderButtons/HeaderButtons';

interface FormProps {
  offer: OfferData | null;
  ready: boolean;
  uneditable: boolean;
  offerDescription: string;
  onOfferDescriptionChange: (event: FormEvent<HTMLTextAreaElement>, data: TextAreaProps) => void;
  serviceFee: string;
  onServiceFeeChange: (event: ChangeEvent<HTMLInputElement>) => void;
  serviceFeeComment: string;
  onServiceFeeCommentChange: (event: FormEvent<HTMLTextAreaElement>, data: TextAreaProps) => void;
  margin: string;
  onMarginChange: (event: ChangeEvent<HTMLInputElement>) => void;
  primaryComment: string;
  onPrimaryCommentChange: (event: FormEvent<HTMLTextAreaElement>, data: TextAreaProps) => void;
  secondaryComment: string;
  onSecondaryCommentChange: (event: FormEvent<HTMLTextAreaElement>, data: TextAreaProps) => void;
  signingFee: string;
  onSigningFeeChange: (event: ChangeEvent<HTMLInputElement>) => void;
  signingFeePaymentTerms: string;
  onSigningFeePaymentTermsChange: (event: ChangeEvent<HTMLInputElement>) => void;
  remainingFeePaymentTerms: string;
  onRemainingFeePaymentTermsChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

function SharedCostForm(props: FormProps): ReactElement<FormProps> {
  const {
    offer,
    ready,
    uneditable,
    offerDescription,
    onOfferDescriptionChange,
    serviceFee,
    onServiceFeeChange,
    serviceFeeComment,
    onServiceFeeCommentChange,
    margin,
    onMarginChange,
    primaryComment,
    onPrimaryCommentChange,
    secondaryComment,
    onSecondaryCommentChange,
    signingFee,
    onSigningFeeChange,
    signingFeePaymentTerms,
    onSigningFeePaymentTermsChange,
    remainingFeePaymentTerms,
    onRemainingFeePaymentTermsChange,
  } = props;

  const [saveButtonLoading, setSaveButtonLoading] = useState(false);
  const [pdfButtonLoading, setPDFButtonLoading] = useState(false);
  const [success, setSuccess] = useState('');
  const [fail, setFail] = useState('');
  const history = useNavigate();
  const confirm = useConfirm();
  const [formValid, setFormValid] = useState(false);

  useEffect(() => {
    if (offerDescription.length > 0
      && margin.toString().length > 0
      && serviceFee.toString().length > 0
      && signingFee.toString().length > 0
      && signingFeePaymentTerms.toString().length > 0
      && remainingFeePaymentTerms.toString().length > 0
    ) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [
    margin,
    offerDescription,
    remainingFeePaymentTerms,
    serviceFee,
    signingFee,
    signingFeePaymentTerms,
  ]);

  function handleSuccess(message: string): void {
    setSuccess(message);
    setFail('');
  }

  function handleFail(message = 'Something went wrong'): void {
    setSuccess('');
    setFail(message);
  }

  async function saveSharedCost(): Promise<void> {
    if (offer === null) {
      return;
    }

    const sharedCost: Partial<SharedCost> = {
      offerDescription,
      serviceFeeInEur: serviceFee,
      serviceFeeComment,
      margin,
      primaryComment,
      secondaryComment,
      signingFee,
      signingFeePaymentTerms: parseInt(signingFeePaymentTerms, 10),
      remainingFeePaymentTerms: parseInt(remainingFeePaymentTerms, 10),
    };

    await updateSharedCost(offer.id, sharedCost);
  }

  async function handleSubmit(event: FormEvent): Promise<void> {
    event.preventDefault();

    setSaveButtonLoading(true);

    try {
      await saveSharedCost();
      setSaveButtonLoading(false);
      handleSuccess('Changes saved successfully');
    } catch (err) {
      setSaveButtonLoading(false);
      handleFail();
      console.error(err);
    }
  }

  function dismissHandler(): void {
    setSuccess('');
    setFail('');
  }

  const handlePDF = async () => {
    setPDFButtonLoading(true);

    if (offer !== null) {
      try {
        await saveSharedCost();
        await createPdf(offer.id);
        setPDFButtonLoading(false);
        handleSuccess('PDF created successfully');
      } catch (err) {
        setPDFButtonLoading(false);
        handleFail();
        console.error(err);
      }
    }
  };
  const handleDOCX = async () => {
    setPDFButtonLoading(true);

    if (offer !== null) {
      try {
        await saveSharedCost();
        await createDocx(offer.id);
        setPDFButtonLoading(false);
        handleSuccess('Document created successfully');
      } catch (err) {
        setPDFButtonLoading(false);
        handleFail();
        console.error(err);
      }
    }
  };

  async function handleDuplicateClick(): Promise<void> {
    if (offer !== null) {
      try {
        await duplicateProject(offer.id);
        handleSuccess('Offer duplicated successfully');
      } catch (error) {
        handleFail();
        console.error(error);
      }
    }
  }

  function remainingFee(): string {
    const signingFeePercentage = parseFloat(signingFee);

    let remainingFeePercentage: number;
    if (Number.isNaN(signingFeePercentage)) {
      remainingFeePercentage = 100;
    } else {
      remainingFeePercentage = 100 - signingFeePercentage;
    }

    return remainingFeePercentage.toString();
  }

  const handleUpdate = async (body: Record<string, unknown>) => {
    if (offer?.id) {
      const updatedOffer = { id: offer.id, ...body };
      const response = await updateOffer(updatedOffer).catch((err) => {
        handleFail();
        console.error(err.response);
      });
      if (response) {
        history('/offers');
      }
    }
  };
  const handleDelete = async (): Promise<void> => {
    if (offer && offer.id) {
      confirm({
        title: 'Are you sure you want to delete this offer?',
        catchOnCancel: true,
        description: 'This will delete all data associated with this offer, this cannot be recovered.',
      })
        .then(async () => {
          const response = await deleteOffer(offer.id).catch((err) => {
            handleFail();
            console.error(err.response);
          });
          if (response) {
            history('/offers');
          }
        })
        .catch(() => {});
    }
  };
  const lockIcon = uneditable ? <div className={styles.lockContainer}><Icon name="lock" /></div> : null;
  const lockOption = offer?.lockedAt === null
    ? <Dropdown.Item disabled={ready === false} text="Lock" onClick={async (): Promise<void> => { await handleUpdate({ lockedAt: new Date().toISOString() }); }} />
    : <Dropdown.Item text="Unlock" onClick={async (): Promise<void> => { await handleUpdate({ lockedAt: null }); }} />;
  const archiveOption = offer?.archivedAt === null
    ? <Dropdown.Item text="Archive" onClick={async (): Promise<void> => { await handleUpdate({ archivedAt: new Date().toISOString() }); }} />
    : <Dropdown.Item text="Unarchive" onClick={async (): Promise<void> => { await handleUpdate({ archivedAt: null }); }} />;
  const deleteOption = offer?.archivedAt !== null
    ? <Dropdown.Item text="Delete" onClick={async (): Promise<void> => { await handleDelete(); }} />
    : null;
  const acceptOption = offer?.acceptedAt === null
    ? <Dropdown.Item text="Mark as accepted" onClick={async (): Promise<void> => { await handleUpdate({ acceptedAt: new Date().toISOString() }); }} />
    : <Dropdown.Item text="Retract acception" onClick={async (): Promise<void> => { await handleUpdate({ acceptedAt: null }); }} />;

  return (
    <Form onSubmit={handleSubmit} size="small">
      <div className={styles.headerRow}>
        {offer && <OfferHeader title={offer.title} type={offer.type} project={(offer.projectNum).toString()} version={offer.version || 'n/a'} />}
        <HeaderButtons>
          <div className={styles.dropdownContainer}>
            <div className="mockButton grey">
              <Dropdown text="Actions" style={{ width: '4rem' }}>
                <Dropdown.Menu>
                  <Dropdown.Item text="Edit" onClick={() => history(`/admin/offers/${offer?.id}/edit`)} />
                  <Dropdown.Item text="Duplicate" onClick={handleDuplicateClick} />
                  {lockOption}
                  {acceptOption}
                  {archiveOption}
                  {deleteOption}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className="mockButton grey">
            <Dropdown text="Download" style={{ width: '5rem' }} disabled={ready === false || !formValid} loading={pdfButtonLoading}>
              <Dropdown.Menu>
                <Dropdown.Item text="Download as PDF" onClick={async (): Promise<void> => { await handlePDF(); }} />
                <Dropdown.Item text="Download as .docx" onClick={async (): Promise<void> => { await handleDOCX(); }} />
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <Button type="submit" primary loading={saveButtonLoading} disabled={!formValid}>Save</Button>
        </HeaderButtons>
      </div>
      <Message body={success || fail} onDismiss={dismissHandler} />
      {lockIcon}
      <Form.TextArea required value={offerDescription} label="Offer Description" onChange={onOfferDescriptionChange} disabled={uneditable} />
      <Form.Group>
        <Form.Input required value={margin} label="Margin" iconPosition="left" onChange={onMarginChange} width={8} disabled={uneditable}>
          <Icon name="percent" />
          <input />
        </Form.Input>
        <Form.Input required value={serviceFee} label="Service Fee" iconPosition="left" onChange={onServiceFeeChange} width={8} disabled={uneditable}>
          <Icon name="euro" />
          <input />
        </Form.Input>
      </Form.Group>
      <Form.TextArea value={serviceFeeComment} label="Service Fee Comment" onChange={onServiceFeeCommentChange} disabled={uneditable} />
      <Form.TextArea value={primaryComment} label="Primary Comment" onChange={onPrimaryCommentChange} disabled={uneditable} />
      <Form.TextArea value={secondaryComment} label="Secondary Comment" onChange={onSecondaryCommentChange} disabled={uneditable} />
      <Form.Group>
        <Form.Input required value={signingFee} label="Signing Fee" iconPosition="left" onChange={onSigningFeeChange} width={8} disabled={uneditable}>
          <Icon name="percent" />
          <input />
        </Form.Input>
        <Form.Field required width={8} disabled={uneditable}>
          <label>Signing Fee Payment Terms</label>
          <Input value={signingFeePaymentTerms} type="number" label={{ basic: true, content: 'days' }} labelPosition="right" onChange={onSigningFeePaymentTermsChange} />
        </Form.Field>
      </Form.Group>
      <Form.Group>
        <Form.Input value={remainingFee()} label="Remaining Fee" iconPosition="left" width={8}>
          <Icon name="percent" />
          <input disabled />
        </Form.Input>
        <Form.Field required width={8} disabled={uneditable}>
          <label>Remaining Fee Payment Terms</label>
          <Input value={remainingFeePaymentTerms} type="number" label={{ basic: true, content: 'days' }} labelPosition="right" onChange={onRemainingFeePaymentTermsChange} />
        </Form.Field>
      </Form.Group>
    </Form>
  );
}

export default SharedCostForm;
