import React, { useState, useEffect } from 'react';
import {
  Input, TextArea, Form, Label, Icon,
} from 'semantic-ui-react';
import styles from './OtherCosts.module.css';
import { OtherCostsProps } from '../../../common/types';

const OtherCosts: React.FC<OtherCostsProps> = (props: OtherCostsProps) => {
  const {
    subCost, onPriceChange, priceData, resetMainPrice, mainPrice,
  } = props;
  const [priceInEur, setPriceInEur] = useState(priceData?.priceInEur || 0);
  const [comment, setComment] = useState(priceData?.comment || '');

  useEffect(() => {
    setPriceInEur(priceData?.priceInEur || 0);
    setComment(priceData?.comment || '');
  }, [priceData]);

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>): void => event.target.select();

  const handleCostChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = +e.target.value;
    setPriceInEur(value);
    onPriceChange({
      subCostId: subCost.id,
      priceInEur: value,
      comment,
    });
    if (mainPrice > 0) resetMainPrice();
  };

  const handleCommentChange = (e: React.SyntheticEvent<HTMLTextAreaElement>): void => {
    setComment(e.currentTarget.value);
    onPriceChange({
      subCostId: subCost.id,
      comment: e.currentTarget.value,
      priceInEur,
    });
  };

  return (
    <Form size="mini" className={styles.container}>
      <Form.Group inline>
        <Form.Field inline width="3">
          <Input
            iconPosition="left"
            size="mini"
            labelPosition="right"
            type="number"
            min="0"
            value={priceInEur}
            onChange={handleCostChange}
          >
            <Icon name="euro" />
            <input onFocus={handleFocus} pattern="[0-9]*" inputMode="decimal" />
            <Label horizontal content="costs" />
          </Input>
        </Form.Field>
        <Form.Field inline width="13" size="mini">
          <TextArea
            rows="1"
            value={comment}
            onInput={handleCommentChange}
            className={styles.textArea}
          />
          <Label horizontal style={{ lineHeight: '20px', textAlign: 'center', marginLeft: '-0.75rem' }} content="comment" />
        </Form.Field>
      </Form.Group>
    </Form>
  );
};

export default OtherCosts;
//
