import React, { ReactElement, ReactNode, useState } from 'react';
import {
  Accordion as AccordionWrapper,
} from 'semantic-ui-react';

import styles from './Accordion.module.css';
import Title from './Title';
import Content from './Content';

interface Props {
  children: ReactNode;
  title: string;
  titleRight?: ReactElement;
}

function Accordion(props: Props): ReactElement<Props> {
  const { children, title, titleRight } = props;

  const [active, setActive] = useState(false);

  function handleAccordionToggle(): void {
    setActive(!active);
  }

  return (
    <AccordionWrapper exclusive={false} className={styles.wrapper}>
      <Title title={title} active={active} onToggle={handleAccordionToggle} right={titleRight} />
      <Content active={active}>
        {children}
      </Content>
    </AccordionWrapper>
  );
}

Accordion.defaultProps = {
  titleRight: undefined,
};

export default Accordion;
