import React, { ReactElement, ReactNode } from 'react';
import { Accordion } from 'semantic-ui-react';
import AnimateHeight from 'react-animate-height';

import styles from './Content.module.css';

interface Props {
  children: ReactNode;
  active: boolean;
}

function Content(props: Props): ReactElement<Props> {
  const { children, active } = props;

  return (
    <Accordion.Content active={active}>
      <AnimateHeight animateOpacity duration={300} height={active ? 'auto' : 0}>
        <div className={styles.wrapper}>
          {children}
        </div>
      </AnimateHeight>
    </Accordion.Content>
  );
}

export default Content;
