import React, { ReactElement, ReactNode } from 'react';

import styled from './HeaderButtons.module.css';

interface Props {
  children: ReactNode;
}

function HeaderButtons(props: Props): ReactElement<Props> {
  const { children } = props;
  return <div className={styled.wrapper}>{children}</div>;
}

export default HeaderButtons;
