import React, { ReactElement } from 'react';
import { Table } from 'semantic-ui-react';

import { SubCostData, Subcategory as SubcategoryData } from '../../common/types';
import { formatEur } from '../../services/utils';
import { hourlyCostInEur, unitCostInEur } from '../../services/calculator';

interface Props {
  subcategory: SubcategoryData;
  data: SubCostData | undefined;
}

function quote(data: SubCostData): string {
  // eslint-disable-next-line max-len
  if (data.hourly.amountInEur !== null && data.hourly.hours !== null && data.hourly.amountInEur !== 0) {
    const hourlyData = { amountInEur: data.hourly.amountInEur, hours: data.hourly.hours };

    return `${data.hourly.hours} h * ${formatEur(data.hourly.amountInEur)} = ${formatEur(hourlyCostInEur(hourlyData))}`;
  }

  if (data.unit.amountInEur !== null && data.unit.units !== null && data.unit.amountInEur !== 0) {
    const unitData = { amountInEur: data.unit.amountInEur, units: data.unit.units };

    return `${data.unit.units} units * ${formatEur(data.unit.amountInEur)} = ${formatEur(unitCostInEur(unitData))}`;
  }

  if (data.main.amountInEur !== null) {
    return `${formatEur(data.main.amountInEur)}`;
  }

  return '-';
}

function Subcategory(props: Props): ReactElement<Props> {
  const { subcategory, data } = props;

  return (
    <Table.Row>
      <Table.Cell>
        <div>{subcategory.title}</div>
        {data?.comment && <div style={{ fontStyle: 'italic' }}>{data?.comment}</div>}
      </Table.Cell>
      <Table.Cell textAlign="right" verticalAlign="top">{data ? quote(data) : '-'}</Table.Cell>
    </Table.Row>
  );
}

export default Subcategory;
