/* eslint-disable max-len */
import React, { useContext } from 'react';

import styles from './List.module.css';

import { BiobankOfferData, OfferData } from '../../common/types';
import { AuthContext } from '../../context/authContext';
import BiobankOfferList from './BiobankOfferList';
import OfferList from './OfferList';

const List: React.FC<{ offerList: (BiobankOfferData | OfferData)[] }> = (props: { offerList: (BiobankOfferData | OfferData)[] }) => {
  const { user, coordinatorView } = useContext(AuthContext);
  const { offerList } = props;
  const listRows = user?.admin || coordinatorView
    ? <OfferList offerList={offerList as Array<OfferData>} />
    : <BiobankOfferList offerList={offerList as Array<BiobankOfferData>} />;

  return (
    <div className={styles.offerListContainer}>
      <ul className={styles.offerList}>
        {listRows}
      </ul>
    </div>
  );
};

export default List;
