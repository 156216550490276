/* eslint-disable @typescript-eslint/type-annotation-spacing */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  createContext, useState, useRef, useContext,
} from 'react';
import { ConfirmOptions } from '../common/types';
import ConfirmDialog from '../components/UI/ConfirmDialog/ConfirmDialog';

export const ConfirmServiceContext = createContext<(
options: ConfirmOptions) => Promise<void>>(Promise.reject,
  );
export const useConfirm = (): any => useContext(ConfirmServiceContext);

export const ConfirmServiceProvider: React.FC = ({ children }): any => {
  const [confirmState, setConfirmState] = useState<ConfirmOptions | null>(null);
  const awaitingPromiseRef = useRef<{ resolve:() => void; reject: () => void }>();
  const openConfirm = (options: ConfirmOptions): Promise<void> => {
    setConfirmState(options);
    return new Promise<void>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };
  const handleClose = () => {
    if (confirmState?.catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }
    setConfirmState(null);
  };
  const handleConfirm = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve();
    }
    setConfirmState(null);
  };

  return (
    <>
      <ConfirmServiceContext.Provider
        value={openConfirm}
      >
        {children}
      </ConfirmServiceContext.Provider>

      <ConfirmDialog
        open={Boolean(confirmState)}
        onConfirm={handleConfirm}
        onClose={handleClose}
        {...confirmState}
      />
    </>
  );
};

export default ConfirmServiceProvider;
