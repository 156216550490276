import React, { ReactElement, useContext } from 'react';
import { Link } from 'react-router-dom';

import { AuthContext } from '../../context/authContext';
import styles from './OfferList.module.css';
import AdminOfferList from '../../components/AdminOfferList/AdminOfferList';
import BiobankOfferList from '../../components/BiobankOfferList/BiobankOfferList';
import ToggleSwitch from '../../components/ToggleSwitch/ToogleSwitch';

function OfferList(): ReactElement<Record<string, never>> | null {
  const { user, coordinatorView, setCoordinatorView } = useContext(AuthContext);

  if (user === null) {
    return null;
  }

  const linkToNewOffer = (
    <div style={{ visibility: user.admin || coordinatorView ? 'initial' : 'hidden' }}>
      <Link to="/admin/offers/new">
        <div className="mockButton blue">+ New Offer</div>
      </Link>
    </div>
  );

  const coordinatorToggle = user.admin
    ? null
    : (
      <ToggleSwitch
        onChange={setCoordinatorView}
        text="Coordinator view"
        initialValue={coordinatorView}
        withMargin
      />
    );

  const OffersList = user.admin || coordinatorView
    ? <AdminOfferList />
    : <BiobankOfferList />;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Offers</h1>
        {linkToNewOffer}
      </div>
      {coordinatorToggle}
      {OffersList}
    </div>
  );
}

export default OfferList;
