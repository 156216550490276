import React, { ReactElement } from 'react';
import { Accordion, Icon } from 'semantic-ui-react';

import styles from './AccordionTitle.module.css';

interface Props {
  title: string;
  active: boolean;
  onToggle: () => void;
  right?: ReactElement;
}

function Title(props: Props): ReactElement<Props> {
  const {
    title, active, onToggle, right,
  } = props;

  return (
    <Accordion.Title active={active} onClick={onToggle}>
      <Icon name="dropdown" />
      {title}

      {right && (
        <div className={styles.right}>
            {right}
        </div>
      )}
    </Accordion.Title>
  );
}

Title.defaultProps = {
  right: undefined,
};

export default Title;
