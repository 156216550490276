import React, { useReducer, useEffect } from 'react';
import { Input, Label, Icon } from 'semantic-ui-react';

import styles from '../SubCosts.module.css';
import { SubCostUiType1Props, SubCostUiType1 } from '../../../common/types';
import SectionItem from '../../SectionItem/SectionItem';

const UiType1: React.FC<SubCostUiType1Props> = (props: SubCostUiType1Props) => {
  const {
    subCost: subMeasure, onPriceChange, mainPrice, resetMainPrice, priceData,
  } = props;

  const priceReducer = (state: SubCostUiType1, action: any):
  SubCostUiType1 => {
    switch (action.type) {
      case 'setHourAmount': return {
        ...state,
        hours: action.payload.hours,
        priceInEur: 0,
      };
      case 'setHourPrice': return {
        ...state,
        pricePerHour: action.payload.pricePerHour,
        priceInEur: 0,
      };
      case 'setTotal': return {
        ...state,
        priceInEur: action.payload.priceInEur,
        hours: 0,
        pricePerHour: 0,
      };
      case 'setPriceState': return {
        ...state,
        hours: action.payload.hours || 0,
        pricePerHour: action.payload.pricePerHour || 0,
        priceInEur: action.payload.priceInEur || 0,
      };
      default: throw new Error('Unexpected action');
    }
  };
  const [priceState, priceDispatch] = useReducer(priceReducer, priceData);

  useEffect(() => {
    priceDispatch({ type: 'setPriceState', payload: { ...priceData } });
  }, [priceData]);

  const onHourAmountChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = +e.target.value;
    priceDispatch({ type: 'setHourAmount', payload: { hours: value } });
    onPriceChange({ ...priceState, hours: value, priceInEur: 0 });
    if (mainPrice > 0) resetMainPrice();
  };

  const onHourPriceChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = +e.target.value;
    priceDispatch({ type: 'setHourPrice', payload: { pricePerHour: value } });
    onPriceChange({ ...priceState, pricePerHour: value, priceInEur: 0 });
    if (mainPrice > 0) resetMainPrice();
  };

  const onTotalChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = +e.target.value;
    priceDispatch({ type: 'setTotal', payload: { priceInEur: value } });
    onPriceChange({
      ...priceState,
      hours: 0,
      pricePerHour: 0,
      priceInEur: value,
    });
    if (mainPrice > 0) resetMainPrice();
  };
  const handleFocus = (event: React.FocusEvent<HTMLInputElement>): void => event.target.select();

  return (
    <SectionItem title={subMeasure.title}>
      <div className={styles.perContainer}>
        <div className={styles.input}>
          <Input
            style={{ width: '3.125rem' }}
            size="mini"
            labelPosition="right"
            type="number"
            min="0"
            onChange={onHourAmountChange}
            value={priceState.hours}
          >
            <input onFocus={handleFocus} pattern="[0-9]*" inputMode="decimal" />
            <Label horizontal content="hours" />
          </Input>
        </div>

        <div className={styles.input}>
          <Input
            style={{ width: '5rem' }}
            size="mini"
            iconPosition="left"
            labelPosition="right"
            type="number"
            min="0"
            onChange={onHourPriceChange}
            value={priceState.pricePerHour}
          >
            <Icon name="euro" />
            <input onFocus={handleFocus} pattern="[0-9]*" inputMode="decimal" />
            <Label horizontal content="per hour" />
          </Input>
        </div>
      </div>
      <div className={styles.or}>or</div>
      <div className={styles.totalContainer}>
        <div className={styles.totalInput}>
          <Input
            style={{ width: '6.25rem' }}
            size="mini"
            iconPosition="left"
            labelPosition="right"
            type="number"
            min="0"
            onChange={onTotalChange}
            value={priceState.priceInEur}
          >
            <Icon name="euro" />
            <input onFocus={handleFocus} pattern="[0-9]*" inputMode="decimal" />
            <Label horizontal content="total" />
          </Input>
        </div>
      </div>
    </SectionItem>
  );
};

export default UiType1;
