/* eslint-disable react/button-has-type */
import React from 'react';
import styles from './Button.module.css';

type Button = {
  color: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  small?: boolean;
};

const DefaultButton: React.FC<Button> = ({
  onClick, color, small, children,
}) => {
  const buttonStyle = color === 'blue' ? styles.buttonBlue : styles.buttonGrey;
  if (small) {
    return (
      <button onClick={onClick} className={styles.smallButton} type="button">{children}</button>
    );
  }

  return (
    <button onClick={onClick} className={buttonStyle} type="button">{children}</button>
  );
};

DefaultButton.defaultProps = {
  onClick: () => {},
  small: false,
};

export default DefaultButton;
