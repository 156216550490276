import React, { ReactElement } from 'react';
import { Button, ButtonProps } from 'semantic-ui-react';

import styled from './HeaderButton.module.css';

type Props = Omit<ButtonProps, 'children' | 'className'>;

function HeaderButton(props: ButtonProps): ReactElement<ButtonProps> {
  const { children, className } = props;
  const buttonProps: Props = { ...props, className: undefined, children: undefined };

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Button {...buttonProps} className={[className, styled.button].join(' ')}>{children}</Button>;
}

export default HeaderButton;
