import React, { useState, useEffect } from 'react';
import {
  Input, Icon, Label,
} from 'semantic-ui-react';
import styles from '../SubCosts.module.css';
import { SubCostUiType4Props } from '../../../common/types';
import SectionItem from '../../SectionItem/SectionItem';

const UiType4: React.FC<SubCostUiType4Props> = (props: SubCostUiType4Props) => {
  const {
    subCost, onPriceChange, mainPrice, resetMainPrice, priceData,
  } = props;
  const [priceInEur, setPriceInEur] = useState(priceData.priceInEur);

  const onTotalChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setPriceInEur(+e.target.value);
    onPriceChange({
      subCostId: subCost.id,
      priceInEur: +e.target.value,
    });
    if (mainPrice > 0) resetMainPrice();
  };
  useEffect(() => {
    setPriceInEur(priceData.priceInEur);
  }, [priceData]);

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>): void => event.target.select();

  return (
    <SectionItem title={subCost.title}>
      <div className={styles.totalInputUI4}>
        <Input
          style={{ width: '6.25rem' }}
          size="mini"
          iconPosition="left"
          labelPosition="right"
          type="number"
          min="0"
          onChange={onTotalChange}
          value={priceInEur}
        >
          <Icon name="euro" />
          <input onFocus={handleFocus} pattern="[0-9]*" inputMode="decimal" />
          <Label horizontal content="total" />
        </Input>
      </div>
    </SectionItem>
  );
};

export default UiType4;
