import React from 'react';
import { Confirm } from 'semantic-ui-react';
import { ConfirmDialogProps } from '../../../common/types';

const ConfirmDialog: React.FC<ConfirmDialogProps> = (props: ConfirmDialogProps) => {
  const {
    open,
    title,
    description,
    onConfirm,
    onClose,
    confirmText,
  } = props;
  return (
    <Confirm
      open={open}
      onCancel={onClose}
      onConfirm={onConfirm}
      header={title}
      content={description}
      confirmButton={confirmText}
      style={{ width: '21.875rem' }}
    />
  );
};

ConfirmDialog.defaultProps = {
  confirmText: 'OK',
};

export default ConfirmDialog;
