import React, { ReactElement } from 'react';

import { MainCostData, SubCostData } from '../../common/types';
import { formatEur } from '../../services/utils';
import { totalInEur } from '../../services/calculator';
import styles from './CategoryTotal.module.css';

interface Props {
  data: Array<MainCostData | SubCostData>;
}

function CategoryTotal(props: Props): ReactElement<Props> {
  const { data } = props;

  return (
    <div className={styles.quote}>{formatEur(totalInEur(data))}</div>
  );
}

export default CategoryTotal;
