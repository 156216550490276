import React, { ReactElement } from 'react';
import { Message as MessageElement, MessageProps } from 'semantic-ui-react';

interface Props {
  body: string;
  onDismiss: (event: React.MouseEvent<HTMLElement>, data: MessageProps) => void;
}

function Message(props: Props): ReactElement<Props> | null {
  const { body, onDismiss } = props;

  if (body.length === 0) {
    return null;
  }

  return <MessageElement onDismiss={onDismiss}>{body}</MessageElement>;
}

export default Message;
