/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { Form, TextArea, Message } from 'semantic-ui-react';
import {
  fetchOffer, fetchPriceData, updatePriceData, publishPriceData,
} from '../../services/api';
import styles from './UnpublishedBiobankOffer.module.css';
import { BiobankOfferData, OfferPriceData } from '../../common/types';
import Button from '../../components/UI/Button/Button';
import CostList from '../../components/CostList/CostList';
import CostBreakDown from '../../components/CostBreakdown/CostBreakdown';
import Container from '../../components/Container/Container';
import OfferHeader from '../../components/OfferHeader/OfferHeader';
import { calculateOfferSum } from '../../services/utils';
import usePrompt from '../../hooks/usePrompt';
import WillNotParticipateCheckbox from '../../components/BiobankOffer/WillNotParticipateCheckbox';

const BiobankOffer: React.FC<any> = (props) => {
  const params: { id?: string | undefined } = useParams();
  const { id } = params;
  const history = useNavigate();
  const { biobankOfferId, initialWillNotParticipate } = props;
  const [offer, setOffer] = useState<BiobankOfferData | null>(null);
  const [priceData, setPriceData] = useState<OfferPriceData | null>(null);
  const [priceDataModified, setPriceDataModified] = useState(false);
  const [offerSum, setOfferSum] = useState(0);
  const [error, setError] = useState(false);
  const [willNotParticipate, setWillNotParticipate] = useState(initialWillNotParticipate);
  usePrompt('You have unsaved changes. If you leave, all unsaved changes will be lost.', priceDataModified);

  useEffect(() => {
    async function load(offerId: string): Promise<void> {
      const loadedOffer = await fetchOffer(offerId);
      setOffer(loadedOffer);
    }
    if (id) load(id);
  }, [id]);

  useEffect(() => {
    async function loadPrices(): Promise<void> {
      const loadedPrices = await fetchPriceData(biobankOfferId);
      if (loadedPrices.mainCostPrices.length > 0) {
        const sum = calculateOfferSum(loadedPrices.mainCostPrices);
        setOfferSum(sum);
      }
      setPriceData(loadedPrices);
    }
    if (biobankOfferId) {
      loadPrices();
    }
  }, [biobankOfferId]);

  const handlePriceDataChange = (data: OfferPriceData): void => {
    setPriceData({ ...data });
    const sum = calculateOfferSum(data.mainCostPrices);
    setOfferSum(sum);
    setPriceDataModified(true);
  };

  const saveOfferData = async (): Promise<void> => {
    if (priceData) {
      setPriceDataModified(false);
      const response = await updatePriceData(priceData).catch((err) => {
        console.error(err.response);
        setError(true);
      });
      if (response && response.status === 204) {
        history('/offers');
      } else {
        setPriceDataModified(true);
        setError(true);
      }
    }
  };

  const publishOfferData = async (participation?: boolean): Promise<void> => {
    if (priceData) {
      setPriceDataModified(false);
      const response = await publishPriceData({ ...priceData, willNotParticipate: !!participation }).catch((err) => {
        console.error(err.response);
        setError(true);
      });
      if (response && response.status === 204) {
        setPriceDataModified(true);
        history('/offers');
      } else {
        setError(true);
      }
    }
  };

  const handleWillNotParticipate = async (accepted: boolean): Promise<void> => {
    setWillNotParticipate(accepted);
    if (accepted) {
      publishOfferData(accepted);
    }
  };

  const handleNotesChange = (e: React.SyntheticEvent<HTMLTextAreaElement>) => {
    setPriceData((prevState: OfferPriceData | null): any => ({
      ...prevState,
      notes: e.currentTarget.value,
    }));
  };

  if (offer && priceData) {
    return (
      <Container>
        <OfferHeader
          offer={offer}
          buttons={[
            <Button key={`${offer.id}publish`} onClick={async (): Promise<void> => { await publishOfferData(); }} color="grey">Publish</Button>,
            <Button key={`${offer.id}save`} onClick={async (): Promise<void> => { await saveOfferData(); }} color="blue">Save</Button>,
          ]}
        />
        <Message compact hidden={!error} warning floating header="Something went wrong" icon="cogs" style={{ width: '22.5rem', marginLeft: 'auto', marginRight: '1.875rem' }} />
        <WillNotParticipateCheckbox onConfirm={handleWillNotParticipate} value={willNotParticipate} />
        <CostList
          priceData={priceData}
          onPriceDataChange={handlePriceDataChange}
          biobankOfferId={biobankOfferId}
          costs={offer.costs}
        />
        <div className={styles.container}>
          <div className={styles.subHeader}>Notes</div>
          <div className={styles.notesContainer}>
            <Form size="tiny">
              <Form.Field>
                <TextArea rows="3" defaultValue={priceData.notes || ''} placeholder="Additional information.." onChange={handleNotesChange} />
              </Form.Field>
            </Form>
          </div>
        </div>
        <CostBreakDown
          costPrices={priceData.mainCostPrices}
        />
        <div className={styles.container}>
          <div className={styles.subHeader}>Total costs</div>
          <div className={styles.costBreakdownRow}>
            <div className={styles.costBreakdownItem}>
              <div>Total</div>
              <div>
                {offerSum.toFixed(2)}
                <span className={styles.currencyIcon}>€</span>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
  return null;
};

export default BiobankOffer;
