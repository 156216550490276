/* eslint-disable no-param-reassign */
import { utcToZonedTime } from 'date-fns-tz';
import { format } from 'date-fns';
import {
  BiobankOfferData, CostBreakDownData, MainCostPrice, OfferData,
} from '../common/types';

export const formatEur = (eur: number): string => `${eur.toFixed(2)} €`;

export const formatTimestamp = (timestamp: string): string => {
  const zonedTime = utcToZonedTime(timestamp, 'Europe/Helsinki');

  return format(zonedTime, 'dd.MM.yyyy');
};

export const mapUiTypeToInitialState = (id: string, uiType: number): any => {
  switch (uiType) {
    case 1:
      return {
        hours: 0,
        pricePerHour: 0,
        priceInEur: 0,
        subCostId: id,
      };
    case 2:
      return {
        hours: 0,
        pricePerHour: 0,
        unitAmount: 0,
        pricePerUnit: 0,
        priceInEur: 0,
        subCostId: id,
      };
    case 3:
      return {
        unitAmount: 0,
        pricePerUnit: 0,
        priceInEur: 0,
        subCostId: id,
      };
    default:
      return {
        priceInEur: 0,
        subCostId: id,
      };
  }
};

export const calculateOfferSum = (mainCostPrices: Array<any>): number => {
  const sum = mainCostPrices.reduce((prevSum, main) => {
    if (main.subCostPrices && main.subCostPrices.length > 0) {
      const subPrices = main.subCostPrices.reduce((prevSubSum: number, sub: any) => {
        if (sub.priceInEur > 0) return prevSubSum + sub.priceInEur;
        if (sub.hours > 0 && sub.pricePerHour > 0) {
          return prevSubSum + (sub.hours * sub.pricePerHour);
        }
        if (sub.unitAmount > 0 && sub.pricePerUnit > 0) {
          return prevSubSum + (sub.unitAmount * sub.pricePerUnit);
        }
        return prevSubSum;
      }, 0);
      return prevSum + subPrices;
    }
    return main.priceInEur > 0 ? prevSum + main.priceInEur : prevSum;
  }, 0);
  return sum;
};

export const calculateCostSums = (mainCostPrices: Array<any>):
Array<CostBreakDownData> => {
  const sums = mainCostPrices.map((main) => {
    if (main.subCostPrices && main.subCostPrices.length > 0) {
      const subPrices = main.subCostPrices.reduce((prevSubSum: number, sub: any) => {
        if (sub.priceInEur > 0) {
          return prevSubSum + sub.priceInEur;
        }
        if (sub.hours > 0 && sub.pricePerHour > 0) {
          return prevSubSum + (sub.hours * sub.pricePerHour);
        }
        if (sub.unitAmount > 0 && sub.pricePerUnit > 0) {
          return prevSubSum + (sub.unitAmount * sub.pricePerUnit);
        }
        return prevSubSum;
      }, 0);
      return { mainCostId: main.mainCostId, sum: subPrices };
    }
    return {
      mainCostId: main.mainCostId,
      sum: (main.priceInEur > 0 ? main.priceInEur : 0),
    };
  });
  return sums;
};

export const createCostPrice = (id: string): MainCostPrice => ({
  mainCostId: id,
  priceInEur: 0,
  subCostPrices: [],
});

export const getFileNameFromHeader = (headerString: string): any => {
  const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const match = regex.exec(headerString);
  return match ? match[1] : 'offer.pdf';
};

export const convertNullValuesToEmptystrings = (obj: Record<string, unknown>): any => (
  Object.keys(obj).reduce((result: any, key) => {
    if (obj[key] === null) {
      result[key] = '';
    } else {
      result[key] = obj[key];
    }
    return result;
  }, {}));

export const getTextForDate = (offer: OfferData | BiobankOfferData): string | null => {
  if (offer.archivedAt) {
    return 'Archived:';
  }
  if (offer.lockedAt) {
    return 'Locked:';
  }
  if ((offer as BiobankOfferData).publishedAt) {
    return 'Published:';
  }
  return null;
};
