import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import StatusDate from './StatusDate';
import { OfferData } from '../../common/types';
import icon from '../../assets/imgs/icons8-file.svg';

import styles from './List.module.css';

interface PropTypes {
  offerList: Array<OfferData>;
}

const OfferList: FC<PropTypes> = ({ offerList }) => (
  <>
    {
      offerList.map(
        (offer: OfferData) => {
          const showAsPublished = !!(offer.lockedAt || offer.archivedAt);
          const statusDate = showAsPublished ? <StatusDate offer={offer} /> : null;
          const className = showAsPublished ? styles.publishedListItem : styles.listItem;
          return (
            <li className={className} key={offer.id}>
              <Link to={`/admin/offers/${offer.id}`}>
                <div className={styles.offer}>
                  <img className={styles.icon} src={icon} alt="icon" />
                  <div className={styles.titleContainer}>
                    <span>#</span>
                    <span className={styles.projectNum}>{offer.projectNum}</span>
                    <span>{offer.title}</span>
                  </div>
                  { statusDate }
                </div>
              </Link>
            </li>
          );
        },
      )
    }
  </>
);

export default OfferList;
