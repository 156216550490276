import React, { useEffect, useState, ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { fetchProject, fetchCosts, fetchBiobankOffers } from '../../services/api';
import { OfferData, CategoryWithSections, BiobankOfferWithCosts } from '../../common/types';
import NarrowContainer from '../../components/NarrowContainer/NarrowContainer';
import BiobankOffer from '../../components/BiobankOffer/BiobankOffer';
import OfferHeader from '../../components/OfferHeaderAlt/OfferHeaderAlt';

function LockedBiobankOffer(): ReactElement<Record<string, never>> | null {
  const params: { id?: string | undefined } = useParams();
  const { id } = params;
  const [offer, setOffer] = useState<OfferData | null>(null);
  const [categories, setCategories] = useState<Array<CategoryWithSections>>([]);
  const [biobankOffers, setBiobankOffers] = useState<Array<BiobankOfferWithCosts>>([]);

  useEffect(() => {
    async function initOffer(): Promise<void> {
      if (id === undefined) {
        return;
      }
      const fetchedProject = await fetchProject(id);
      setOffer(fetchedProject);
    }
    async function initCosts(): Promise<void> {
      const fetchedCosts = await fetchCosts();

      setCategories(fetchedCosts);
    }
    async function initBiobankOffers(): Promise<void> {
      if (id === undefined) {
        return setBiobankOffers([]);
      }
      const fetchedBiobankOffers = await fetchBiobankOffers(id);
      return setBiobankOffers(fetchedBiobankOffers);
    }
    initOffer();
    initCosts();
    initBiobankOffers();
  }, [id]);

  if (offer === null) {
    return null;
  }
  return (
    <NarrowContainer>
      <OfferHeader title={offer.title} type={offer.type} project={offer.projectNum.toString()} version={offer.version || 'n/a'} />
      {biobankOffers.map((biobankOffer) => (
        <BiobankOffer
          key={biobankOffer.id}
          categories={categories}
          data={biobankOffer}
        />
      ))}
    </NarrowContainer>
  );
}

export default LockedBiobankOffer;
