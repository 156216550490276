import React, { ReactElement } from 'react';
import { Table } from 'semantic-ui-react';

interface Props {
  title: string;
}

function TableHeader(props: Props): ReactElement<Props> {
  const { title } = props;

  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell colSpan="2">{title}</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
}

export default TableHeader;
