import React, { ReactElement } from 'react';
import { Header } from 'semantic-ui-react';

import { formatTimestamp } from '../../services/utils';
import style from './OfferHeaderAlt.module.css';

interface Props {
  title: string;
  type: string;
  project: string;
  version: string;
}

function OfferHeader(props: Props): ReactElement<Props> {
  const {
    title, type, project, version,
  } = props;

  const content = `${title} - ${type}`;
  const subheader = `#${project} - ${formatTimestamp(version)}`;

  return (
    <div className={style.altHeaderContainer}>
      <Header as="h1" content={content} subheader={subheader} />
    </div>
  );
}

export default OfferHeader;
