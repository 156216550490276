import React, { ReactElement } from 'react';

import {
  CategoryWithSections, BiobankOfferWithCosts, SubCostData,
} from '../../common/types';
import CategoryTotal from './CategoryTotal';
import Section from './Section';
import Accordion from './Accordion';

interface Props {
  category: CategoryWithSections;
  data: BiobankOfferWithCosts;
}

function Category(props: Props): ReactElement<Props> {
  const { category, data } = props;

  const subcategoryIds: Array<string> = category.sections.reduce(
    (arr, curr) => [...arr, ...curr.costs.map((cost) => cost.id)],
    [] as Array<string>,
  );

  const categoryCosts = data.costs.filter((item) => {
    if (item.type === 'main' && item.id === category.id) {
      return true;
    }

    return item.type === 'sub' && subcategoryIds.includes(item.id);
  });

  const subcategoryCosts: Array<SubCostData> = categoryCosts.filter((cost) => cost.type === 'sub') as Array<SubCostData>;

  return (
    <Accordion
      title={category.title}
      titleRight={<CategoryTotal data={categoryCosts} />}
    >
      {category.sections.map((section) => (
        <Section
          key={section.id}
          section={section}
          data={subcategoryCosts}
        />
      ))}
    </Accordion>
  );
}

export default Category;
