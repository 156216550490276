import styled from 'styled-components';

const StyledCenteredParagraph = styled.p`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default StyledCenteredParagraph;
