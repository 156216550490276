/* eslint-disable max-len */
import React, {
  ReactElement, useState, useEffect, useContext,
} from 'react';
import { Tab } from 'semantic-ui-react';
import { BiobankOfferData, User } from '../../common/types';
import { AuthContext } from '../../context/authContext';
import { fetchOffers } from '../../services/api';
import List from '../List/List';

function BiobankOfferList(): ReactElement<Record<string, never>> {
  const [activeOffers, setActiveOffers] = useState([] as Array<BiobankOfferData>);
  const [lockedOffers, setLockedOffers] = useState([] as Array<BiobankOfferData>);
  const [publishedOffers, setPublishedOffers] = useState([] as Array<BiobankOfferData>);
  const [archivedOffers, setArchivedOffers] = useState([] as Array<BiobankOfferData>);
  const [acceptedOffers, setAcceptedOffers] = useState([] as Array<BiobankOfferData>);

  const [activeIndex, setActiveIndex] = useState(0);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    async function load(userData: User): Promise<void> {
      const loadedOffers: Array<BiobankOfferData> = await fetchOffers(userData);
      if (loadedOffers && loadedOffers.length > 0) {
        const active = loadedOffers.filter(
          (offer: BiobankOfferData) => (
            !offer.archivedAt && !offer.acceptedAt && !offer.publishedAt && !offer.lockedAt),
        );
        setActiveOffers(active);
        const published = loadedOffers.filter(
          (offer: BiobankOfferData) => offer.publishedAt && !offer.lockedAt && !offer.archivedAt && !offer.acceptedAt,
        );
        setPublishedOffers(published);
        const accepted = loadedOffers.filter(
          (offer: BiobankOfferData) => offer.acceptedAt && !offer.archivedAt,
        );
        setAcceptedOffers(accepted);
        const locked = loadedOffers.filter((offer: BiobankOfferData) => (
          offer.lockedAt && !offer.archivedAt && !offer.acceptedAt));
        setLockedOffers(locked);
        const archived = loadedOffers.filter((offer: BiobankOfferData) => offer.archivedAt);
        setArchivedOffers(archived);
      }
    }
    if (user && user.biobankId) {
      load(user);
    }
  }, [user]);

  const panes = [
    {
      menuItem: 'Active',
      render: () => (
        <Tab.Pane attached={false}>
          <List offerList={activeOffers} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Published',
      render: () => (
        <Tab.Pane attached={false}>
          <List offerList={publishedOffers} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Locked',
      render: () => (
        <Tab.Pane attached={false}>
          <List offerList={lockedOffers} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Accepted',
      render: () => (
        <Tab.Pane attached={false}>
          <List offerList={acceptedOffers} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Archived',
      render: () => (
        <Tab.Pane attached={false}>
          <List offerList={archivedOffers} />
        </Tab.Pane>
      ),
    },
  ];

  const handleTabChange = (e: any, data: any) => {
    setActiveIndex(data.activeIndex);
  };

  return (
    <Tab
      menu={{ secondary: true, pointing: true }}
      panes={panes}
      activeIndex={activeIndex}
      onTabChange={handleTabChange}
    />
  );
}

export default BiobankOfferList;
