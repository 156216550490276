import React, { ReactElement } from 'react';
import { Accordion, Card } from 'semantic-ui-react';

import { CategoryWithSections, BiobankOfferWithCosts } from '../../common/types';
import BiobankHeader from './BiobankHeader';
import OfferComment from '../OfferComment/OfferComment';
import Category from './Category';

interface Props {
  categories: Array<CategoryWithSections>;
  data: BiobankOfferWithCosts;
}

function BiobankOfferSummary(props: Props): ReactElement<Props> {
  const { categories, data } = props;

  return (
    <Card fluid>
      <Card.Content>
        <Card.Header>
          <BiobankHeader
            publishedAt={data.publishedAt}
            title={data.biobank}
            data={data.costs}
            willNotParticipate={data.willNotParticipate}
          />
        </Card.Header>
      </Card.Content>
      { (!data.willNotParticipate || data.notes) && (
      <Card.Content>
        {data.notes && <OfferComment body={data.notes} />}
        { !data.willNotParticipate && (
          <Accordion exclusive={false} styled fluid>
            {categories.map((category) => (
              <Category
                key={category.id}
                category={category}
                data={data}
              />
            ))}
          </Accordion>
        )}
      </Card.Content>
      )}
    </Card>
  );
}

export default BiobankOfferSummary;
