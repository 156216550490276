import React, { ReactElement } from 'react';
import { Header } from 'semantic-ui-react';

interface Props {
  body: string;
}

function OfferComment(props: Props): ReactElement<Props> {
  const { body } = props;

  return (
    <>
      <Header as="h4">Comments</Header>
      <p style={{ marginBottom: '1rem' }}>{body}</p>
    </>
  );
}

export default OfferComment;
