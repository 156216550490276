import React, { ReactElement } from 'react';
import { Card } from 'semantic-ui-react';

import { MainCostData, SubCostData } from '../../common/types';
import { formatEur } from '../../services/utils';
import { totalInEur } from '../../services/calculator';

interface Props {
  margin: number;
  serviceFee: number;
  data: Array<MainCostData | SubCostData>;
}

function OfferTotal(props: Props): ReactElement<Props> {
  const { margin, serviceFee, data } = props;

  const total: number = (totalInEur(data)) * (1 + margin) + serviceFee;

  return (
    <Card fluid>
      <Card.Content>
        <Card.Header>
          <div style={{ float: 'left' }}>Total</div>
          <div style={{ float: 'right' }}>{formatEur(total)}</div>
        </Card.Header>
      </Card.Content>
    </Card>
  );
}

export default OfferTotal;
