import React, { ReactElement, ReactNode } from 'react';

import styles from './SectionItem.module.css';

interface Props {
  children: ReactNode;
  title: string;
}

function SectionItem(props: Props): ReactElement<Props> {
  const { children, title } = props;

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{title}</div>
      <div className={styles.right}>
        {children}
      </div>
    </div>
  );
}

export default SectionItem;
