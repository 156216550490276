import React, { ReactElement } from 'react';
import { Label } from 'semantic-ui-react';

import styles from './BiobankHeader.module.css';
import { MainCostData, SubCostData } from '../../common/types';
import { formatTimestamp, formatEur } from '../../services/utils';
import { totalInEur } from '../../services/calculator';

interface Props {
  publishedAt: string | null;
  title: string;
  data: Array<MainCostData | SubCostData>;
  willNotParticipate?: boolean;
}

function BiobankHeader(props: Props): ReactElement<Props> {
  const {
    publishedAt,
    title,
    data,
    willNotParticipate,
  } = props;
  const status: string = publishedAt ? formatTimestamp(publishedAt) : 'WAITING';

  return (
    <>
      <div style={{ float: 'left' }}>
        <span className={styles.title}>{title}</span>
        <Label color={publishedAt ? 'grey' : 'red'} style={{ verticalAlign: 'top' }} size="tiny">{status}</Label>
      </div>
      <div style={{ float: 'right' }}>
        { willNotParticipate
          ? 'Will not participate'
          : formatEur(totalInEur(data))}
      </div>
    </>
  );
}

BiobankHeader.defaultProps = {
  willNotParticipate: false,
};

export default BiobankHeader;
