import styled from 'styled-components';

export const StyledText = styled.p`
  ${({ theme }) => theme.objects.Copy.Base}
`;

export const StyledHeading = styled.p`
  ${({ theme }) => theme.objects.Heading.Scale.XLarge}
  ${({ theme }) => theme.objects.Heading.Style.Primary}
`;

export const StyledMessageContent = styled.div`
  ${({ theme }) => theme.mixins.Container('COLUMN', { alignItems: 'center' })}
`;

export const StyledActionableMessage = styled.div`
  ${({ theme }) => theme.mixins.Container('COLUMN', { alignItems: 'center', gap: theme.values.spacing.LARGE })} `;
