import React from 'react';
import { Message } from 'semantic-ui-react';

const NotFound = (): any => (
  <div style={{ margin: '4rem auto' }}>
    <Message warning header="404" content="Page you we're looking for cannot be found" />
  </div>
);

export default NotFound;
