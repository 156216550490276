import React, {
  createContext, useState, ReactNode,
} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { AuthOptions, User } from '../common/types';

export const AuthContext = createContext<AuthOptions>({} as AuthOptions);

export const AuthProvider = ({ children }: { children: ReactNode }): any => {
  const [user, setUser] = useState<User | null>(null);
  const [authenticated, setAuthenticated] = useState(false);
  const [coordinatorView, setCoordinatorView] = useState(false);
  const { logout } = useAuth0();

  const logOutUser = async (): Promise<void> => {
    logout();

    setUser(null);
    setAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{
      user,
      authenticated,
      coordinatorView,
      logOutUser,
      setAuthenticated,
      setUser,
      setCoordinatorView,
    }}
    >
      {children}
    </AuthContext.Provider>
  );
};
