import React, {
  ReactElement, useState, useEffect, useContext,
} from 'react';

import { Tab } from 'semantic-ui-react';
import { OfferData } from '../../common/types';
import { fetchProjects } from '../../services/api';
import List from '../List/List';
import { AuthContext } from '../../context/authContext';

function AdminOfferList(): ReactElement<Record<string, never>> {
  const [activeOffers, setActiveOffers] = useState<Array<OfferData>>([]);
  const [lockedOffers, setLockedOffers] = useState<Array<OfferData>>([]);
  const [acceptedOffers, setAcceptedOffers] = useState<Array<OfferData>>([]);
  const [archivedOffers, setArchivedOffers] = useState<Array<OfferData>>([]);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    async function initOffers(): Promise<void> {
      const fetchedProjects: Array<OfferData> = await fetchProjects(!!user?.admin);
      const active = fetchedProjects.filter(
        (offer: OfferData) => !offer.archivedAt && !offer.acceptedAt && !offer.lockedAt,
      );
      setActiveOffers(active);
      const accepted = fetchedProjects.filter(
        (offer: OfferData) => offer.acceptedAt,
      );
      setAcceptedOffers(accepted);
      const locked = fetchedProjects.filter(
        (offer: OfferData) => offer.lockedAt && !offer.archivedAt && !offer.acceptedAt,
      );
      setLockedOffers(locked);
      const archived = fetchedProjects.filter((offer: OfferData) => offer.archivedAt);
      setArchivedOffers(archived);
    }

    initOffers();
  }, [user?.admin]);

  const panes = [
    {
      menuItem: 'Active',
      render: () => (
        <Tab.Pane attached={false}>
          <List offerList={activeOffers} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Locked',
      render: () => (
        <Tab.Pane attached={false}>
          <List offerList={lockedOffers} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Accepted',
      render: () => (
        <Tab.Pane attached={false}>
          <List offerList={acceptedOffers} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Archived',
      render: () => (
        <Tab.Pane attached={false}>
          <List offerList={archivedOffers} />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <Tab
      menu={{ secondary: true, pointing: true }}
      panes={panes}
    />
  );
}

export default AdminOfferList;
