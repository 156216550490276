import React, { FC, FormEvent } from 'react';
import { Checkbox, CheckboxProps } from 'semantic-ui-react';
import styled from 'styled-components';

const Container = styled.div`
  margin: 0.5rem 0 1rem ${({ withMargin }) => (withMargin ? '7rem' : '0')};
  
  .ui.toggle.checkbox input:checked~.box:before, .ui.toggle.checkbox input:checked~label:before {
    background-color: ${({ theme }) => theme.values.colors.brand.BASE} !important;
  }
`;

interface PropTypes {
  onChange: (value: boolean) => void;
  text: string;
  initialValue?: boolean;
  withMargin?: boolean;
}

const ToggleSwitch: FC<PropTypes> = ({
  onChange, text, initialValue, withMargin,
}) => {
  const onClick = (event: FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    onChange(!!data.checked);
  };

  return (
    <Container withMargin={withMargin}>
      <Checkbox
        defaultChecked={initialValue}
        label={text}
        onChange={onClick}
        toggle
      />
    </Container>
  );
};

ToggleSwitch.defaultProps = {
  withMargin: false,
  initialValue: false,
};

export default ToggleSwitch;
