import React, { ReactElement } from 'react';

import { BiobankOfferData, OfferData } from '../../common/types';
import styles from './OfferHeader.module.css';

interface Props {
  offer: BiobankOfferData | OfferData | null;
  buttons: Array<ReactElement>;
}

function OfferHeader(props: Props): ReactElement<Props> {
  const { offer, buttons } = props;

  return (
    <div className={styles.wrapper} key={offer?.id}>
      <div className={styles.left}>
        <h1>{`${offer?.title} - ${offer?.type}`}</h1>
        <div className={styles.projectNumber}>
          <span>#</span>
          {offer?.projectNum}
        </div>
      </div>
      {buttons.map((button) => button)}
    </div>

  );
}

export default OfferHeader;
