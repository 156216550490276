import React, { ReactElement, ReactNode } from 'react';

import styles from './NarrowContainer.module.css';

interface Props {
  children: ReactNode;
}

function NarrowContainer(props: Props): ReactElement<Props> {
  const { children } = props;

  return (
    <div className={styles.wrapper}>
      {children}
    </div>
  );
}

export default NarrowContainer;
