import { AxiosResponse, AxiosResponseHeaders } from 'axios';

import {
  Biobank,
  BiobankOfferData,
  OfferPriceData,
  MainCost,
  SharedCost,
  CategoryWithSections,
  BiobankOfferWithCosts,
  OfferData,
  NewOfferData,
  User,
} from '../common/types';
import axios from './client';
import { getFileNameFromHeader } from './utils';

export const setAuthToken = (token: string): void => {
  axios.interceptors.request.use(
    (existingConfig) => {
      if (token && existingConfig) {
        return {
          ...existingConfig,
          headers: {
            Authorization: `Bearer ${token}`,
            ...existingConfig.headers,
          },
        };
      }
      return existingConfig;
    },
    (error) => Promise.reject(error),
  );
};

export const removeAuthToken = (): void => {
  axios.interceptors.request.use(
    (config) => config,
    (error) => Promise.reject(error),
  );
};

export const fetchBiobanks = async (): Promise<Array<Biobank>> => {
  const response: AxiosResponse = await axios.get('/biobanks');
  return response.data;
};

export const fetchProjects = async (admin: boolean): Promise<Array<OfferData>> => {
  const url = admin ? '/projects' : '/biobank/projects';
  const response: AxiosResponse = await axios.get(url);

  return response.data;
};

export const fetchProject = async (id: string): Promise<OfferData> => {
  const response: AxiosResponse = await axios.get(`/projects/${id}`);

  return response.data;
};

export const duplicateProject = async (id: string): Promise<OfferData> => {
  const response: AxiosResponse = await axios.post(`/admin/projects/${id}/duplicate`);

  return response.data;
};

export const fetchOffers = async (
  user: User,
): Promise<Array<BiobankOfferData>> => {
  const response: AxiosResponse = await axios.get('/offers', {
    params: { biobankId: user.biobankId },
  });
  return response.data;
};

export const fetchOffer = async (id: string): Promise<any> => {
  const response: AxiosResponse = await axios.get(`/offers/${id}`);
  return response.data;
};

export const fetchEditOfferData = async (id: string): Promise<any> => {
  const response: AxiosResponse = await axios.get(`admin/offer/${id}/edit`);
  return response.data;
};

export const saveEditedOffer = async (offer: NewOfferData): Promise<AxiosResponse> => {
  const response: AxiosResponse = await axios.post(`admin/offer/${offer.id}/edit`, offer);
  return response;
};

export const fetchBiobankOffers = async (
  offerId: string,
): Promise<Array<BiobankOfferWithCosts>> => {
  const response: AxiosResponse = await axios.get(`/offers/${offerId}/biobanks`);

  return response.data;
};

export const fetchBiobankOffer = async (offerId: string): Promise<BiobankOfferWithCosts> => {
  const response: AxiosResponse = await axios.get(`/offers/${offerId}/biobanks/current`);

  return response.data;
};

export const getUser = async (): Promise<User> => {
  const response: AxiosResponse = await axios.get('/user');
  return response.data;
};
export const logOut = async (): Promise<any> => {
  const response: AxiosResponse = await axios.get('/logout');

  return response.data;
};

export const saveNewOffer = async (offer: Record<string, unknown>): Promise<{ id: string }> => {
  const response: AxiosResponse = await axios.post('/offers', offer);
  return response.data;
};

export const updateSharedCost = async (
  offerId: string,
  sharedCost: Partial<SharedCost>,
): Promise<SharedCost> => {
  const response: AxiosResponse = await axios.put(
    `/admin/offers/${offerId}/shared_costs`,
    sharedCost,
  );

  return response.data;
};

export const fetchSharedCosts = async (offerId: string): Promise<SharedCost> => {
  const response: AxiosResponse = await axios.get(
    `/admin/offers/${offerId}/shared_costs`,
  );

  return response.data;
};

export const fetchPriceData = async (
  biobankOfferId: string,
): Promise<OfferPriceData> => {
  const response: AxiosResponse = await axios.get('/costprices', {
    params: { biobankOfferId },
  });
  return response.data;
};

export const updatePriceData = async (
  priceData: OfferPriceData,
): Promise<AxiosResponse> => {
  const response: AxiosResponse = await axios.put('/costprices', priceData);
  return response;
};

export const publishPriceData = async (
  priceData: OfferPriceData,
): Promise<AxiosResponse> => {
  const response: AxiosResponse = await axios.post('/publish', priceData);
  return response;
};

export const cancelPublish = async (
  biobankOfferId: string,
): Promise<AxiosResponse> => {
  const response: AxiosResponse = await axios.put('/publish', {
    biobankOfferId,
  });
  return response;
};

export const fetchCosts = async (): Promise<Array<CategoryWithSections>> => {
  const response: AxiosResponse = await axios.get('/costs');

  return response.data;
};

export const fetchMainCosts = async (): Promise<Array<MainCost>> => {
  const response: AxiosResponse = await axios.get('/maincosts');
  return response.data;
};

const getContentDisposition = (headers: AxiosResponseHeaders): string => {
  if (headers && headers['content-disposition']) {
    return headers['content-disposition'];
  }
  return '';
};

const createFileLink = (response: AxiosResponse): void => {
  const header = getContentDisposition(response.headers);
  const fileName = getFileNameFromHeader(header);
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
};

export const createPdf = async (offerId: string): Promise<void> => {
  const response: AxiosResponse = await axios({
    url: 'admin/pdf', method: 'GET', responseType: 'arraybuffer', params: { offerId },
  });
  if (response.data) {
    createFileLink(response);
  }
};

export const createDocx = async (offerId: string): Promise<void> => {
  const response: AxiosResponse = await axios({
    url: 'admin/docx', method: 'GET', responseType: 'arraybuffer', params: { offerId },
  });
  if (response.data) {
    createFileLink(response);
  }
};

export const updateOffer = async (offer: Record<string, unknown>): Promise<AxiosResponse> => {
  const response: AxiosResponse = await axios.put(`admin/offer/${offer.id}/update`, offer);
  return response;
};

export const deleteOffer = async (offerId: string): Promise<any> => {
  const response: AxiosResponse = await axios.delete(`admin/offer/${offerId}`);
  return response;
};
