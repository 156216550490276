/* eslint-disable max-len */
import React, { useState, useEffect, useReducer } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Form, Loader, Message } from 'semantic-ui-react';
import styles from './EditOffer.module.css';
import { fetchBiobanks, fetchEditOfferData, saveEditedOffer } from '../../services/api';
import { Biobank } from '../../common/types';
import { convertNullValuesToEmptystrings } from '../../services/utils';

const EditOffer = (): any => {
  const params: { id?: string | undefined } = useParams();
  const { id } = params;
  const history = useNavigate();
  const [biobankOptions, setBiobankOptions] = useState<Array<Biobank> | null>(null);
  const [loading, setLoading] = useState(true);
  const [isFormValid, setIsFormValid] = useState(false);
  const [showAlertMessage, setShowAlertMessage] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [initialBiobanks, setInitialBiobanks] = useState<Array<string> | undefined>();

  const reducer = (state: any, action: any): any => {
    switch (action.type) {
      case 'setState': return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
      case 'initialize': return action.payload;
      default: throw new Error('Unexpected action');
    }
  };

  const [state, dispatch] = useReducer(reducer, null);
  useEffect(() => {
    async function load(offerId: string): Promise<void> {
      try {
        const loadedOffer = await fetchEditOfferData(offerId);
        const loadedBiobanks = await fetchBiobanks();
        if (loadedBiobanks) {
          setBiobankOptions(loadedBiobanks);
        }
        const initOffer = convertNullValuesToEmptystrings(loadedOffer);
        dispatch({ type: 'initialize', payload: { ...initOffer, biobanks: [...loadedOffer.biobanks] } });
        setInitialBiobanks([...loadedOffer.biobanks]);
        setLoading(false);
      } catch (err: any) {
        console.error(err.response);
        setError(true);
      }
    }
    if (id) load(id);
  }, [id]);

  const onFormSubmit = async (e: any): Promise<void> => {
    setError(false);
    e.preventDefault();
    const editedOffer = { ...state, biobanks: [...state.biobanks] };
    const response = await saveEditedOffer(editedOffer).catch((err) => {
      console.error(err.response);
      setError(true);
    });
    if (response) {
      setError(false);
      setShowSuccess(true);
      setTimeout(() => history('/offers'), 5000);
    }
  };

  const toggleBiobankSelected = (toggledBiobank: Biobank): void => {
    if (state.biobanks.includes(toggledBiobank.id)) {
      const filtered = state.biobanks.filter((biobankId: string) => biobankId !== toggledBiobank.id);
      dispatch({ type: 'setState', payload: { name: 'biobanks', value: [...filtered] } });
      setShowAlertMessage(true);
    } else {
      const selected = state.biobanks.concat(toggledBiobank.id);
      dispatch({ type: 'setState', payload: { name: 'biobanks', value: [...selected] } });
      if (initialBiobanks && initialBiobanks.every((biobankId: string) => selected.includes(biobankId))) {
        setShowAlertMessage(false);
      }
    }
  };
  const handleChange = (name: string, value: string): void => {
    dispatch({ type: 'setState', payload: { name, value } });
  };
  const biobankCheckBoxes = biobankOptions?.map((biobank: Biobank) => {
    const selected = state?.biobanks?.includes(biobank.id);
    return (
      <Form.Checkbox
        key={biobank.id}
        label={biobank.name}
        checked={!!selected}
        onChange={(): void => toggleBiobankSelected(biobank)}
      />
    );
  });
  useEffect(() => {
    if (state && state.title.length > 0
        && state.projectNum.length > 0
        && state.researcher.length > 0
        && state.researcherEmail.length > 0
        && state.finbbContact.length > 0
        && state.finbbContactEmail.length > 0
        && state.biobanks.length > 0) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [state]);
  if (!state || loading) {
    return <Loader />;
  }
  return (
    <div className={styles.container}>
      <header><h1>Edit offer</h1></header>
      <Form className={styles.form} size="small" onSubmit={onFormSubmit} error>
        <div className={styles.radioGroupContainer}>
          <Form.Radio
            label="Estimate"
            name="offerTypeGroup"
            value="Estimate"
            required
            onChange={(e, data) => handleChange('type', data.value as string)}
            checked={state?.type === 'Estimate'}
            className={styles.radioGroupItem}
          />
          <Form.Radio
            label="Offer"
            name="offerTypeGroup"
            value="Offer"
            required
            onChange={(e, data) => handleChange('type', data.value as string)}
            checked={state?.type === 'Offer'}
            className={styles.radioGroupItem}
          />
        </div>
        <Form.Input
          value={state?.title}
          required
          label="Project name"
          onChange={(e) => handleChange('title', e.target.value)}
        />
        <Form.Input
          value={state?.projectNum}
          required
          type="text"
          label="Project number"
          onChange={(e) => handleChange('projectNum', e.target.value)}
        />
        <Form.Input
          value={state?.researcher}
          required
          type="text"
          label="Researcher"
          onChange={(e) => handleChange('researcher', e.target.value)}
        />
        <Form.Input
          value={state?.researcherEmail}
          required
          type="email"
          label="Researcher email"
          onChange={(e) => handleChange('researcherEmail', e.target.value)}
        />
        <Form.Input
          value={state?.companyName}
          type="text"
          label="Company name"
          onChange={(e) => handleChange('companyName', e.target.value)}
        />
        <Form.Input
          value={state?.companyAddress1}
          type="text"
          label="Company address (line 1)"
          onChange={(e) => handleChange('companyAddress1', e.target.value)}
        />
        <Form.Input
          value={state?.companyAddress2}
          type="text"
          label="Company address (line 2)"
          onChange={(e) => handleChange('companyAddress2', e.target.value)}
        />
        <h2 className={styles.checkboxHeader}>FINBB contact person</h2>
        <Form.Input
          value={state?.finbbContact}
          required
          type="text"
          label="Contact name"
          onChange={(e) => handleChange('finbbContact', e.target.value)}
        />
        <Form.Input
          value={state?.finbbContactEmail}
          required
          type="email"
          label="Contact email"
          onChange={(e) => handleChange('finbbContactEmail', e.target.value)}
        />
        <h2 className={styles.checkboxHeader}>Select biobanks</h2>
        {biobankCheckBoxes}
        <Message warning visible={showAlertMessage} content="Removing biobanks from the offer also deletes all their price data, this cannot be recovered" />
        <div className={styles.submitButton}>
          <div className="mockButton grey">
            <Link className={styles.cancel} to="/offers">Cancel</Link>
          </div>
          <Form.Button
            circular
            onClick={onFormSubmit}
            color="blue"
            disabled={!isFormValid}
          >
            Save
          </Form.Button>
        </div>
        <Message info hidden={!showSuccess} content="Changes saved" />
        <Message negative hidden={!error} content="Something went wrong" />
      </Form>
    </div>
  );
};

export default EditOffer;
