import React, { FC, FormEvent } from 'react';
import { CheckboxProps, Form } from 'semantic-ui-react';

import getUserConfirmation from '../UI/ConfirmDialog/getUserConfirmation';
import styles from './WillNotParticipateCheckbox.module.css';

interface PropTypes {
  onConfirm: (accepted: boolean) => void;
  value: boolean;
}

const CONFIRM_MESSAGE = 'Are you sure that your biobank will not participate in this project cost calculation? '
    + 'By clicking the Accept-button this project becomes unavailable for any further cost calculations for your biobank.';

const WillNotParticipateCheckbox: FC<PropTypes> = ({ onConfirm, value }) => {
  const onChange = (event?: FormEvent<HTMLInputElement>, data?: CheckboxProps): void => {
    const callBack = (accepted) => {
      if (accepted) {
        onConfirm(accepted);
      }
    };
    if (data?.checked) {
      getUserConfirmation(CONFIRM_MESSAGE, callBack, 'Accept');
    } else {
      onConfirm(false);
    }
  };

  return (
    <div className={styles.willNotParticipateContainer}>
      <Form.Checkbox
        checked={value}
        label=""
        onChange={onChange}
      />
      <button type="button" onClick={() => onChange(undefined, { checked: !value })}>
        <span>We will not participate</span>
      </button>
    </div>
  );
};

export default WillNotParticipateCheckbox;
